<template>
  <div
    v-show="selectedPaymentMethod === 'credit-card'"
    class="card-fields-container"
  >
    <card-fields-braintree
      v-if="gatewayName == 'braintree'"
      :gateway="gateway"
      :order="order"
      :label-cols="labelCols"
      :label-cols-sm="labelColsSm"
      :label-cols-md="labelColsMd"
      :label-cols-lg="labelColsLg"
      :label-cols-xl="labelColsXl"
    />
    <card-fields-stripe
      v-if="gatewayName == 'stripe'"
      :gateway="gateway"
      :order="order"
      :label-cols="labelCols"
      :label-cols-sm="labelColsSm"
      :label-cols-md="labelColsMd"
      :label-cols-lg="labelColsLg"
      :label-cols-xl="labelColsXl"
    />
  </div>
</template>

<script>
import CardFieldsBraintree from "components/CardFieldsBraintree.vue";
import CardFieldsStripe from "components/CardFieldsStripe.vue";

export default {
  components: {
    CardFieldsBraintree,
    CardFieldsStripe
  },
  props: {
    labelCols: { default: null, type: Number },
    labelColsSm: { default: null, type: Number },
    labelColsMd: { default: null, type: Number },
    labelColsLg: { default: null, type: Number },
    labelColsXl: { default: null, type: Number },
    gateway: {
      default: () => {},
      type: Object
    },
    order: {
      default: () => ["number", "expiry", "cvc"],
      type: Array
    },
    selectedPaymentMethod: {
      required: true,
      type: String
    }
  },
  computed: {
    gatewayName() {
      return this.gateway && this.gateway.provider;
    }
  }
};
</script>
