import currenciesData from "./currency_iso.json";

class Money {
  constructor(amount, currency) {
    this.amount = amount;
    this.currency = currency;
  }

  format() {
    // Load currency data to know how to properly format the currency amount
    const currencyData = currenciesData[this.currency];
    const { subunit_to_unit, symbol, symbol_first } = currencyData;

    // Use rails I18n for seperators and decimal mark, since this is language specific,
    // NOT currency specific
    const {
      delimiter: thousands_separator,
      seperator: decimal_mark
    } = window.I18n.t("number.currency.format");

    // We have data about how many subunits there are in one unit,
    // but we rather need to know the number of digits after the decimal
    const decimalCount = Math.floor(Math.log10(subunit_to_unit));

    // Format the number with the right number of decimals and seperators
    let string = this.formatNumber(
      decimalCount,
      decimal_mark,
      thousands_separator
    );

    // Add the currency symbol, either first or last
    // eslint-disable-next-line camelcase
    string = symbol_first ? `${symbol}${string}` : `${string} ${symbol}`;

    return string;
  }

  formatNumber(decimalCount = 2, decimal = ".", thousands = ",") {
    let number = this.amount;

    const negativeSign = number < 0 ? "-" : "";

    const i = parseInt(
      (number = Math.abs(Number(number) || 0).toFixed(decimalCount)),
      10
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(number - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  }
}

export default Money;
