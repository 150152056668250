<template>
  <div id="brendon-burchard">
    <b-container class="hybrid-container">
      <b-row>
        <b-col md="5" order="1" order-md="2">
          <div class="sidebar">
            <img
              v-if="mainProductImagePath"
              :src="mainProductImagePath"
              class="img-fluid product-image"
            />
            <payment-options
              v-model="selectedPaymentOptions"
              :products="products"
              show-total="moreThanOne"
              stacked
            />
          </div>
        </b-col>
        <b-col md="7" order="2" order-md="1" class="main-container">
          <div class="main">
            <hideable
              name="header"
              :visible-by-default="true"
              :show="sections['header']"
            >
              <center>
                <editable name="headline" :content="regions['headline']">
                  <h2>Complete Your Purchase Below</h2>
                </editable>
              </center>
              <hr />
            </hideable>
            <hideable
              name="sub_header"
              :visible-by-default="true"
              :show="sections['sub_header']"
            >
              <div class="checkout-login-section" style="text-align: center;">
                <editable
                  name="instructions"
                  :content="regions['instructions']"
                >
                  <p>Please fill in the form below to secure your order.</p>
                </editable>
              </div>
              <hr />
            </hideable>

            <h4>
              {{
                I18n.t(
                  "views.checkout_pages.templates.common.account information"
                )
              }}
            </h4>
            <input-first-name v-model="firstName" :requires="requires" />
            <input-last-name v-model="lastName" :requires="requires" />
            <input-email v-model="email" :requires="requires" />
            <hideable
              name="support"
              :visible-by-default="true"
              :show="sections['support']"
            >
              <div class="checkout-support">
                <editable name="support" :content="regions['support']">
                  <p>
                    Need Help?
                    <a :href="`mailto:${supportEmail}`"
                      >Email Customer Support</a
                    >
                  </p>
                </editable>
              </div>
            </hideable>

            <input-billing-address
              v-model="billingAddress"
              :requires="requires"
            >
              <h4>
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.billing address"
                  )
                }}
              </h4>
            </input-billing-address>

            <input-shipping-address
              v-model="shippingAddress"
              :requires="requires"
            >
              <h4>
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.shipping address"
                  )
                }}
              </h4>
            </input-shipping-address>

            <payment-method
              v-model="selectedPaymentMethod"
              :payment-methods="paymentMethods"
            />

            <div
              v-show="selectedPaymentMethod === 'credit-card'"
              class="credit-card-area"
            >
              <h4>
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.billing information"
                  )
                }}
              </h4>

              <div class="credit-card-logos">
                <icon name="cc-visa" scale="3" />
                <icon name="cc-mastercard" scale="3" />
                <icon name="cc-amex" scale="3" />
                <icon name="cc-jcb" scale="3" />
                <icon name="cc-discover" scale="3" />
                <icon name="cc-diners-club" scale="3" />
              </div>
              <card-fields
                :checkout-page-options="checkoutPageOptions"
                :gateway="paymentMethods['credit-card']"
                :selected-payment-method="selectedPaymentMethod"
              />
              <hideable
                name="security_notice"
                :visible-by-default="true"
                :show="sections['security_notice']"
              >
                <p class="checkout-privacy">
                  <icon name="lock" />
                  <span>Card information is stored on a secure server.</span>
                </p>
              </hideable>
            </div>

            <h4>
              {{
                I18n.t(
                  "views.checkout_pages.templates.common.additional information"
                )
              }}
            </h4>
            <input-phone v-model="phone" :requires="requires" />
            <error-messages :errors="baseErrors" />
            <button-submit
              block
              size="lg"
              variant="primary"
              :loading="loading"
              :selected-payment-method="selectedPaymentMethod"
              >{{
                I18n.t(
                  "views.checkout_pages.templates.common.complete my purchase"
                )
              }}</button-submit
            >
            <captcha-notice :use-captcha="useCaptcha" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  computed: {
    productImageSize() {
      return "md";
    }
  },
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        label: "none",
        placeholder: ""
      },
      layout: {
        horizontal: false
      }
    });
  }
};
</script>

<style lang="scss">
#brendon-burchard {
  @import "css/bootstrap3_legacy.scss";

  background-color: #f7f8f9;
  font-family: "proxima-nova", sans-serif;

  a {
    color: #2e91fc;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #595959;
  }

  h2 {
    font-size: 24px;
  }

  h4,
  .payment-method-headline {
    color: #252525;
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  hr {
    border-top: 1px solid #e3e5e5;
    margin: 30px 0;
  }

  .form-group {
    border-color: rgb(227, 229, 229);
    margin-bottom: 25px;
  }

  button,
  .country-select .vs__dropdown-toggle,
  .form-control,
  .payment-field {
    border-radius: 2px;
  }

  .country-select,
  .form-control,
  .payment-field {
    font-size: 15px;
  }

  .form-control,
  .payment-field {
    padding: 10px;

    &:focus,
    &.is-focused {
      border-color: #2e91fc;
      box-shadow: none;
    }
  }

  .country-select {
    .vs__dropdown-toggle {
      padding-top: 0;
      padding-bottom: 4px;
    }

    .vs__search,
    .vs__selected {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .country-select.vs--open .vs__dropdown-toggle {
    border-color: #2e91fc;
    box-shadow: none;
  }

  //
  // Bootstrap
  //
  .form-control {
    height: inherit;
  }
  .country-select .vs__search,
  .form-control {
    &::placeholder {
      color: #999999;
    }
  }

  .hybrid-container {
    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }
    @media (min-width: 768px) {
      max-width: 750px;
    }
    @media (min-width: 992px) {
      max-width: 970px;
    }
    @media (min-width: 1200px) {
      max-width: 1170px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    background: #fff
      linear-gradient(90deg, #fff 0%, #fff 50%, #f7f8f9 50%, #f7f8f9 100%);
  }

  .main-container {
    background: #fff;

    .main {
      padding: 70px 0;
      padding-top: 30px;

      @media (min-width: 768px) {
        padding-bottom: 100px;
        padding-right: 15px;
        padding-top: 60px;
      }

      .card-number {
        margin-bottom: 40px;
      }

      // Size card fields
      @media (min-width: 992px) {
        .card-fields {
          display: flex;
          flex-flow: row wrap;

          .card-number {
            width: 100%;
          }

          .card-expiry {
            padding-right: 15px;
            width: 58.33333333%;
          }

          .card-cvc {
            padding-left: 15px;
            width: 41.66666667%;
          }
        }
      }

      .checkout-login-section {
        color: #888c94;
      }

      .checkout-privacy {
        align-items: center;
        color: #595959;
        display: flex;
        font-size: 15px;
        margin-bottom: 50px;

        .fa-icon {
          margin-right: 12px;
        }
      }

      .checkout-support {
        color: #595959;
        font-size: 15px;
        margin-bottom: 30px;
      }

      .credit-card-logos {
        color: #bcbcbc;
        margin-bottom: 20px;
      }

      .payment-method {
        margin-bottom: 20px;
      }

      .button-submit {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;
        padding: 12px 30px;
      }
    }
  }

  .sidebar {
    background: #fff;
    border: 1px solid #e3e5e5;
    margin: 30px 0;
    margin-bottom: 60px;

    @media (min-width: 768px) {
      margin-left: 15px;
      margin-top: 60px;
    }

    .payment-options {
      color: #999;
      margin: 20px;

      p {
        margin-bottom: 0;
      }

      .product-name,
      .total-description {
        color: #595959;
        font-size: 24px;
      }

      .product-price {
        color: #42be8a;
        display: block;
        font-size: 24px;
        margin-bottom: 10px;
      }

      .payment-option {
        font-size: 15px;
      }

      .product-row {
        border-bottom: 1px solid #e3e5e5;
        margin-bottom: 30px;
        padding-bottom: 30px;
      }

      .total-amount {
        color: #42be8a;
      }

      .total-row {
        font-size: 24px;
      }
    }
  }
}
</style>
