import InputCountry from "components/InputCountry.vue";
import Input from "components/Input.vue";

const props = {
  value: {
    required: true,
    type: String
  }
};

export default {
  components: {
    InputCountry,
    VInput: Input
  },
  props,
  data: () => ({
    innerValue: ""
  }),
  computed: {
    I18n() {
      return window.I18n;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  }
};
