<template>
  <div>
    <!-- `content` has already been sanitized on the server and should be safe to render directly -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="content" data-editable :data-name="name" v-html="content" />
    <div v-else data-editable :data-name="name">
      <slot />
    </div>
  </div>
</template>

<script>
import { camelCase } from "lodash";

export default {
  props: {
    content: {
      default: undefined,
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    }
  },
  computed: {
    formattedName() {
      return camelCase(this.name);
    }
  }
};
</script>
