<template>
  <b-row class="product-row">
    <b-col>
      <span class="product-name" v-html="productName" />
      <span v-if="stacked" class="product-price">{{
        formattedProductPrice
      }}</span>
      <payment-options-product-required
        v-if="required"
        v-model="innerValue"
        :product-id="product.id"
        :payment-options="checkoutPagePaymentOptionsOrdered"
      />
      <payment-options-product-optional
        v-else
        v-model="innerValue"
        :product-id="product.id"
        :payment-options="checkoutPagePaymentOptionsOrdered"
      />
    </b-col>
    <b-col v-if="!stacked" cols="auto" class="product-price-col">
      <span class="product-price">{{ formattedProductPrice }}</span>
    </b-col>
  </b-row>
</template>

<script>
import { sortBy } from "lodash";

import Money from "utils/Money";
import PaymentOptionsProductOptional from "components/PaymentOptionsProductOptional.vue";
import PaymentOptionsProductRequired from "components/PaymentOptionsProductRequired.vue";

export default {
  components: {
    PaymentOptionsProductOptional,
    PaymentOptionsProductRequired
  },
  props: {
    paymentOptions: {
      required: true,
      type: Array
    },
    product: {
      required: true,
      type: Object
    },
    productName: {
      required: true,
      type: String
    },
    required: {
      required: true,
      type: Boolean
    },
    stacked: {
      default: false,
      type: Boolean
    },
    value: {
      default: "",
      type: String
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    currency() {
      return this.product.currency;
    },
    checkoutPagePaymentOptionsOrdered() {
      return sortBy(this.paymentOptions, ["position"]);
    },
    defaultPaymentOption() {
      const paymentOption = this.paymentOptions.find(
        checkoutPagePaymentOption => checkoutPagePaymentOption.selectedByDefault
      );

      if (paymentOption) return paymentOption;

      // If the product is `required`, we a payment option should be selected by default
      return this.required ? this.paymentOptions[0] : undefined;
    },
    formattedProductPrice() {
      if (typeof this.productPrice !== "number" || !this.currency) {
        return null;
      }
      const money = new Money(this.productPrice, this.currency);
      return money.format();
    },
    productPrice() {
      const { selectedCheckoutPagePaymentOption } = this;
      if (!selectedCheckoutPagePaymentOption) return null;

      return selectedCheckoutPagePaymentOption.paymentOption.initialAmount;
    },
    selectedCheckoutPagePaymentOption() {
      return this.paymentOptions.find(
        checkoutPagePaymentOption =>
          checkoutPagePaymentOption.paymentOption.id === this.value
      );
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  },
  created() {
    // Select the default payment option, if any
    const defaultPaymentOptionValue =
      (this.defaultPaymentOption &&
        this.defaultPaymentOption.paymentOption.id) ||
      "";

    this.$emit("input", defaultPaymentOptionValue);
  }
};
</script>
