<template>
  <div id="material">
    <b-container class="container-960">
      <hideable
        name="header"
        :visible-by-default="true"
        :show="sections['header']"
      >
        <header>
          <b-row>
            <b-col md="6" class="text-center text-lg-left">
              <img :src="logoImagePath" class="company-logo" />
            </b-col>
            <b-col md="6" class="d-none d-sm-block text-right support-text">
              <hideable
                name="support"
                :visible-by-default="true"
                :show="sections['support']"
              >
                <editable name="support" :content="regions['support']">
                  <p>
                    Need help?
                    <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
                  </p>
                </editable>
              </hideable>
            </b-col>
          </b-row>
        </header>
      </hideable>
      <div class="main">
        <div class="content-header">
          <b-row>
            <b-col cols="9" md="3" order="1">
              <img
                v-if="mainProductImagePath"
                :src="mainProductImagePath"
                class="img-fluid product-shot"
              />
            </b-col>
            <b-col
              cols="3"
              md="3"
              order="2"
              order-md="3"
              class="ribbon-container"
            >
              <hideable
                name="guarantee"
                :visible-by-default="true"
                :show="sections['guarantee']"
              >
                <img
                  src="@rails/assets/images/templates/material/guarantee-ribbon.png"
                  class="guarantee-ribbon img-fluid"
                />
              </hideable>
            </b-col>
            <b-col cols="12" md="6" order="3" order-md="2">
              <div class="header-text">
                <editable name="headline" :content="regions['headline']">
                  <h1>{{ mainProductName }}</h1>
                </editable>
                <editable name="description" :content="regions['description']">
                  <p>Get instant access to {{ mainProductName }} now.</p>
                </editable>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col lg="5" order-lg="2">
            <div class="sidebar">
              <editable
                name="sidebar_headline"
                :content="regions['sidebar_headline']"
              >
                <h2>Order today and get...</h2>
              </editable>
              <editable
                name="sidebar_bullets"
                :content="regions['sidebar_bullets']"
              >
                <ul class="small-checks">
                  <li>Instant access to {{ mainProductName }}</li>
                </ul>
              </editable>
              <div class="testimonials">
                <editable
                  name="sidebar_testimonials"
                  :content="regions['sidebar_testimonials']"
                >
                  <h2>What others are saying...</h2>
                  <p>
                    <i>“Thank you – This really worked well for me" - Bob</i>
                  </p>
                </editable>
              </div>
            </div>
          </b-col>
          <b-col lg="7">
            <div class="checkout">
              <h2>
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.contact information"
                  )
                }}
              </h2>
              <p
                v-html="
                  I18n.t(
                    'views.checkout_pages.templates.common.fields marked with are mandatory',
                    { mark: '<span class=\'required\'>*</span>' }
                  )
                "
              />
              <b-row>
                <b-col lg="6">
                  <input-first-name v-model="firstName" :requires="requires" />
                </b-col>
                <b-col lg="6">
                  <input-last-name v-model="lastName" :requires="requires" />
                </b-col>
              </b-row>
              <input-email v-model="email" :requires="requires" />
              <input-phone v-model="phone" :requires="requires" />

              <input-billing-address
                v-model="billingAddress"
                :requires="requires"
              >
                <h2>
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.billing information"
                    )
                  }}
                </h2>
                <p
                  v-html="
                    I18n.t(
                      'views.checkout_pages.templates.common.fields marked with are mandatory',
                      { mark: '<span class=\'required\'>*</span>' }
                    )
                  "
                />
              </input-billing-address>
              <input-shipping-address
                v-model="shippingAddress"
                :requires="requires"
              >
                <h2>
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.shipping information"
                    )
                  }}
                </h2>
                <p
                  v-html="
                    I18n.t(
                      'views.checkout_pages.templates.common.fields marked with are mandatory',
                      { mark: '<span class=\'required\'>*</span>' }
                    )
                  "
                />
              </input-shipping-address>

              <div class="payment-details">
                <payment-method
                  v-model="selectedPaymentMethod"
                  :payment-methods="paymentMethods"
                />
                <div
                  v-show="selectedPaymentMethod === 'credit-card'"
                  class="credit-card-area"
                >
                  <h2>
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.payment information"
                      )
                    }}
                  </h2>
                  <p>
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.all fields are mandatory"
                      )
                    }}
                  </p>
                  <div class="credit-card-logos">
                    <img
                      src="@rails/assets/images/system/credit_cards/light_color/visa.png"
                      class="credit-card"
                    />
                    <img
                      src="@rails/assets/images/system/credit_cards/light_color/mastercard.png"
                      class="credit-card"
                    />
                    <img
                      src="@rails/assets/images/system/credit_cards/light_color/amex.png"
                      class="credit-card"
                    />
                    <img
                      src="@rails/assets/images/system/credit_cards/light_color/jcb.png"
                      class="credit-card"
                    />
                    <img
                      src="@rails/assets/images/system/credit_cards/light_color/discover.png"
                      class="credit-card"
                    />
                    <img
                      src="@rails/assets/images/system/credit_cards/light_color/diners.png"
                      class="credit-card"
                    />
                  </div>
                  <card-fields
                    :checkout-page-options="checkoutPageOptions"
                    :gateway="paymentMethods['credit-card']"
                    :selected-payment-method="selectedPaymentMethod"
                    :order="['number', 'cvc', 'expiry']"
                  />
                </div>
              </div>

              <payment-options
                v-model="selectedPaymentOptions"
                :products="products"
                single-payment-option-html=" "
                :total-description-text="
                  I18n.t('views.checkout_pages.templates.common.total')
                "
              />

              <div class="trust-footer">
                <b-row>
                  <b-col cols="8" class="align-self-center">
                    <div class="credit-card-logos-bw">
                      <b-row no-gutters>
                        <b-col cols="2">
                          <img
                            src="@rails/assets/images/system/credit_cards/bw/1.png"
                            class="credit-card img-fluid"
                          />
                        </b-col>
                        <b-col cols="2">
                          <img
                            src="@rails/assets/images/system/credit_cards/bw/2.png"
                            class="credit-card img-fluid"
                          />
                        </b-col>
                        <b-col cols="2">
                          <img
                            src="@rails/assets/images/system/credit_cards/bw/22.png"
                            class="credit-card img-fluid"
                          />
                        </b-col>
                        <b-col cols="2">
                          <img
                            src="@rails/assets/images/system/credit_cards/bw/16.png"
                            class="credit-card img-fluid"
                          />
                        </b-col>
                        <b-col cols="2">
                          <img
                            src="@rails/assets/images/system/credit_cards/bw/14.png"
                            class="credit-card img-fluid"
                          />
                        </b-col>
                        <b-col cols="2">
                          <img
                            src="@rails/assets/images/system/credit_cards/bw/10.png"
                            class="credit-card img-fluid"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <!--
                  -->
                  <b-col cols="1" class="align-self-center">
                    <div class="lock">
                      <icon name="lock" />
                    </div>
                  </b-col>
                  <!--
                  -->
                  <b-col cols="3" class="align-self-center">
                    <img
                      src="@rails/assets/images/templates/material/comodo_secure_h100.png"
                      class="img-fluid"
                    />
                  </b-col>
                </b-row>
              </div>

              <error-messages :errors="baseErrors" />
              <button-submit
                block
                variant="submit"
                :loading="loading"
                :selected-payment-method="selectedPaymentMethod"
                >{{
                  I18n.t(
                    "views.checkout_pages.templates.common.place order now"
                  )
                }}
              </button-submit>
            </div>
          </b-col>
        </b-row>
      </div>

      <hideable
        name="footer"
        :visible-by-default="true"
        :show="sections['footer']"
      >
        <footer>
          <b-row>
            <b-col cols="3" class="align-self-center">
              <img :src="logoImagePath" class="img-fluid" />
            </b-col>
            <!--
            -->
            <b-col cols="9" class="text-right footer-text align-self-center">
              <editable name="footer_text" :content="regions['footer_text']">
                <p>
                  &copy; paycartpro.com 2015 - {{ new Date().getFullYear() }} |
                  <a target="_blank" href="#">Privacy Policy</a> |
                  <a target="_blank" href="#">Terms Of Service</a> |
                  <a :href="`mailto:${supportEmail}`">Customer Support</a>
                </p>
              </editable>
              <captcha-notice :use-captcha="useCaptcha" />
            </b-col>
          </b-row>
        </footer>
      </hideable>
    </b-container>
  </div>
</template>

<script>
import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  data() {
    return {
      showCvcExplanation: false,
      stepCounter: 0
    };
  },
  computed: {
    shippingStep() {
      return this.checkoutPage.requireBillingAddress === true ? 3 : 2;
    },
    paymentStep() {
      let step = 2;
      if (this.checkoutPage.requireBillingAddress === true) {
        step += 1;
      }
      if (this.checkoutPage.requireShippingAddress === true) {
        step += 1;
      }
      return step;
    }
  },
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        placeholder: "none",
        showIcon: true
      },
      layout: {
        horizontal: false
      }
    });
  }
};
</script>

<style lang="scss">
#material {
  @import "css/bootstrap3_legacy.scss";

  // Google Fonts
  @import url("//fonts.googleapis.com/css?family=Lato:300,400,700");

  // Bootstrap extend
  .container-960 {
    max-width: 960px;
  }

  /*
  * Responsive text aligning
  * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
  */
  .text-xs-left {
    text-align: left;
  }
  .text-xs-right {
    text-align: right;
  }
  .text-xs-center {
    text-align: center;
  }
  .text-xs-justify {
    text-align: justify;
  }

  @media (min-width: 768px) {
    .text-sm-left {
      text-align: left;
    }
    .text-sm-right {
      text-align: right;
    }
    .text-sm-center {
      text-align: center;
    }
    .text-sm-justify {
      text-align: justify;
    }
  }

  @media (min-width: 992px) {
    .text-md-left {
      text-align: left;
    }
    .text-md-right {
      text-align: right;
    }
    .text-md-center {
      text-align: center;
    }
    .text-md-justify {
      text-align: justify;
    }
  }

  @media (min-width: 1200px) {
    .text-lg-left {
      text-align: left;
    }
    .text-lg-right {
      text-align: right;
    }
    .text-lg-center {
      text-align: center;
    }
    .text-lg-justify {
      text-align: justify;
    }
  }

  // Variables
  $border-radius: 5px;

  // CSS Start

  // body
  background: url("~@rails/assets/images/templates/material/bg-pattern2.png")
      top no-repeat,
    url("~@rails/assets/images/templates/material/bg-pattern2.png") bottom
      no-repeat,
    url("~@rails/assets/images/templates/material/bg.png") left top repeat-x,
    url("~@rails/assets/images/templates/material/bg.png") left bottom repeat-x,
    #f2f2f2;
  background-size: auto, auto, 1px, 0.8px; // this would not display the bottom of the background in Chrome
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: 300;

  p {
    font-size: 14px;
  }

  h2,
  .payment-method-headline {
    font-size: 30px;
  }

  .col-form-label {
    font-size: 16px;
    font-weight: bold;
    color: #888;
    padding: 0 0 8px;
  }

  header {
    .support-text {
      margin-top: 25px;
    }
  }

  header,
  footer {
    padding: 25px 0;
    p,
    a,
    a:hover {
      font-size: 18px;
      color: #ffffff;
      text-decoration: none;
      margin: 0;
    }
    .company-logo {
      max-height: 50px;
    }
  }

  footer {
    color: #a1a1a1;
    padding: 50px 25px;
    p,
    a,
    a:hover {
      color: #a1a1a1;
      font-size: 13px;
    }
  }

  // vue-awesome
  .fa-icon {
    width: auto;
    height: 1em; /* or any other relative font sizes */

    /* You would have to include the following two lines to make this work in Safari */
    max-width: 100%;
    max-height: 100%;
  }

  .main {
    background: #ffffff;
    border-collapse: separate;
    border-radius: $border-radius;
    position: relative; // Needed for the .content-header:after border to be positioned correctly
    &:before {
      background: linear-gradient(
        to right,
        #25c5f4 25%,
        #f37f08 25%,
        #f37f08 50%,
        #e84f47 50%,
        #e84f47 75%,
        #ffca02 75%
      );
      position: absolute;
      content: "";
      height: 4px;
      right: 0;
      left: 0;
      top: 0;
      border-radius: $border-radius $border-radius 0 0;
      // top-left       top-right      bottom-right bottom-left
    }
    &:after {
      background: linear-gradient(
        to right,
        #25c5f4 25%,
        #f37f08 25%,
        #f37f08 50%,
        #e84f47 50%,
        #e84f47 75%,
        #ffca02 75%
      );
      position: absolute;
      content: "";
      height: 4px;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 0 0 $border-radius $border-radius;
      // top-left top-right bottom-right   bottom-left
    }
    .content-header {
      background: #f7f7f7;
      border-bottom: 1px solid #e4e4e4;
      border-radius: $border-radius $border-radius 0 0;
      // top-left       top-right      bottom-right bottom-left
      padding-bottom: 25px;
      h1 {
        color: #585858;
        font-size: 34px;
      }
      p {
        color: #666;
        font-size: 20px;
      }
      .product-shot {
        max-height: 250px;
        padding-left: 20px;
        padding-top: 20px;
      }

      .header-text {
        margin-top: 20px;
      }

      .ribbon-container {
        padding-right: 30px;
        z-index: 1; // otherwise the border will cover the top of it
      }
    }
    .sidebar {
      background: #f8ffe2;
      padding: 30px 45px;
      padding-top: 50px;

      h2 {
        text-align: center;
        padding-bottom: 20px;
      }
      .small-checks {
        color: #8a9a5f;
        font-size: 16px;
        font-weight: 400;
        list-style-image: url("~@rails/assets/images/templates/material/check-green.png");
        padding: 0 0 20px 25px;
        li {
          padding-bottom: 15px;
        }
      }
      .testimonials {
        p {
          color: #656565;
          font-family: Georgia;
          font-size: 15px;
          margin-bottom: 40px;
        }
      }
    }

    .checkout {
      padding: 30px 45px;
      padding-top: 50px;

      h2,
      .payment-method-headline {
        text-transform: capitalize;
      }

      .input-group-text {
        background-color: #f7f7f7;
      }

      .form-control:focus + .input-group-prepend {
        border: 1px solid #0cf;
        border-right: 0;

        .input-group-text {
          background: #fff;
          border: 0;
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      .payment-field {
        background: #f7f7f7;
        border-radius: 2px;
        box-shadow: none;
        box-sizing: border-box;
        display: block;
        font-size: 20px;
        height: initial;
        padding: 15px 12px;

        &:focus {
          background: #fff;
          border: 1px solid #0cf;
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"] {
        border: 1px solid #d7d7d7;
        border-left: none;
        padding-left: 0;

        &:focus {
          border-left: none;
        }
      }

      .country-select {
        background-color: #f7f7f7;
        font-size: 20px;
        font-weight: 400;

        &.vs--open {
          border: 1px solid #0cf;

          .vs__dropdown-toggle {
            border: none;
            box-shadow: none;
          }
        }

        .vs__dropdown-toggle {
          border: 1px solid #d7d7d7;
          border-radius: 2px;
        }

        .vs__search,
        .vs__selected {
          padding-top: 9px;
          padding-bottom: 9px;
          padding-left: 26px;
        }
      }

      p {
        span {
          &.required {
            color: red;
          }
        }
      }
      .credit-card-logos {
        margin: 25px 0;
        .credit-card {
          border: 0.5px solid #d7d7d7;
          border-radius: 2px;
          max-height: 33px;
        }
      }

      .payment-details {
        margin-bottom: 25px;

        .payment-field {
          background: #fffced;
          border: 1px solid #e7daaf;
          padding-left: 15px;
          padding-right: 15px;
          width: 100%;

          &:focus,
          &.is-focused {
            border: 1px solid #c4bb8b;
            background: #fff;
          }
        }

        .card-fields {
          display: flex;
          flex-wrap: wrap;

          .card-number,
          .card-cvc,
          .card-expiry {
            width: 100%;
          }

          @media (min-width: 992px) {
            .card-number {
              padding-right: 15px;
              width: 66.66666667%;
            }
            .card-cvc {
              padding-left: 15px;
              width: 33.33333333%;
            }
          }
        }
      }
    }

    .product-row,
    .total-row {
      background: #e9e9e9;
      border: 2px solid #e9e9e9;
      border-radius: 3px;
      margin-bottom: 20px;
      padding-top: 15px;

      label {
        color: #000;
        font-size: 14px;
        font-weight: normal;

        div {
          margin-left: 40px;
        }
      }

      .payment-option {
        background: #00c0f0;
        border-radius: 3px;
        display: flex;
        margin-bottom: 20px;
        padding: 25px;
        padding-bottom: 10px;

        label {
          color: #fff;
          position: relative;
        }
      }
    }

    .product-row {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0;

      > .col {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 10px;
      }

      .product-price-col {
        display: none;
      }

      .product-name {
        font-weight: normal;
        order: 12;
      }
    }

    .total-row {
      font-size: 25px;
      font-weight: 400;
      padding: 15px;
    }

    .trust-footer {
      margin-bottom: 35px;
      .credit-card-logos-bw {
        border: 1px solid #cecece;
        border-radius: 3px;
        padding: 6px 0px;
      }
      .lock {
        align-self: center;
        color: #cecece;
        display: flex;
        text-align: center;
        font-size: 32px;
        min-width: 20px;
      }
    }
    .btn-submit {
      background: #a8d92f;
      background: linear-gradient(to bottom, #a8d92f 0, #7ec620 100%);
      border: none;
      border-radius: 3px;
      box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.08);
      clear: both;
      color: #fff;
      cursor: pointer;
      display: block;
      font-family: Lato, Arial, Helvetica, sans-serif;
      font-size: 30px;
      margin: 10px auto;
      padding: 20px 15px;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      white-space: normal;

      &:hover {
        background: #bbec42;
        background: linear-gradient(to bottom, #bbec42 0, #8ed72f 100%);
      }

      p {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    .content-header {
      .header-text {
        padding: 0 20px;
      }
    }
  }

  @media (max-width: 991px) {
    .main {
      .sidebar {
        border-radius: 5px;
        margin: 20px;
      }
    }
  }
}
</style>
