import inputBaseMixin from "mixins/inputBaseMixin";

export default {
  mixins: [inputBaseMixin],
  props: {
    required: {
      required: true,
      type: Boolean
    }
  }
};
