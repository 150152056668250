<template>
  <vue-recaptcha :sitekey="siteKey" size="invisible" @verify="onVerify" />
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapState } from "vuex";

import EventBus from "mixins/eventBus";

export default {
  components: {
    VueRecaptcha
  },
  computed: {
    ...mapState({
      siteKey: state => state.credentials.recaptcha.siteKey
    })
  },
  methods: {
    onVerify(response) {
      EventBus.$emit("captchaVerified", response);
    }
  }
};
</script>
