import humps from "humps";
import { camelCase, defaultTo, get, pick, sumBy } from "lodash";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import { mapState } from "vuex";

import AddressResource from "api/Address";
import CustomerResource from "api/Customer";
import LineItemResource from "api/LineItem";
import OrderResource from "api/Order";
import OrderConfirmationResource from "api/OrderConfirmation";
import OrderFinalizationResource from "api/OrderFinalization";
import PaymentOptionResource from "api/PaymentOption";
import EventBus from "mixins/eventBus";
import stripeMixin from "mixins/stripeMixin";
import store from "store";

import DefaultLogoImagePath from "@rails/assets/images/templates/ryan_deiss/paycartpro-logo.png";

const props = {
  checkoutPage: {
    required: true,
    type: Object
  },
  loading: {
    required: true,
    type: Boolean
  },
  validationObserver: {
    required: true,
    type: Object
  },
  validationErrors: {
    required: true,
    type: Object
  }
};

export default {
  props,
  data() {
    return {
      // Checkout page state
      captchaVerified: false,
      // Order
      selectedPaymentMethod: this.defaultPaymentMethod,
      selectedPaymentOptions: {},
      token: "",
      // Customer
      firstName: "",
      email: "",
      lastName: "",
      phone: "",
      billingAddress: { category: "billing" },
      shippingAddress: { category: "shipping" }
    };
  },
  computed: {
    account() {
      return this.checkoutPage.account;
    },
    baseErrors() {
      return this.validationErrors.base;
    },
    currency() {
      return this.checkoutPage.currency;
    },
    defaultPaymentMethod() {
      return Object.keys(this.paymentMethods)[0];
    },
    horizontal() {
      return this.checkoutPageOptions.layout.horizontal;
    },
    I18n() {
      return window.I18n;
    },
    logo() {
      return this.account.businessLogo;
    },
    logoImagePath() {
      return get(this.logo, "banner_md.url", null) || DefaultLogoImagePath;
    },
    mainCheckoutPageProduct() {
      return (
        this.checkoutPage.checkoutPageProducts.find(
          checkoutPageProduct =>
            checkoutPageProduct.product.id === this.mainProductId
        ) || {}
      );
    },
    mainProduct() {
      return this.mainCheckoutPageProduct.product;
    },
    mainProductId() {
      return this.checkoutPage.paymentOptionOrder[1].id;
    },
    mainProductImagePath() {
      return get(
        this.mainProduct.image,
        `${this.mainProductImageSize}.url`,
        null
      );
    },
    mainProductImageSize() {
      return defaultTo(this.productImageSize, "sm");
    },
    mainProductName() {
      return this.mainProduct.name;
    },
    mainProductTermsInWords() {
      return this.mainCheckoutPageProduct.checkoutPagePaymentOptions[0].label.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      );
    },
    name() {
      return `${this.firstName} ${this.lastName}`.trim();
    },
    orderData() {
      const { currency, locale } = this.checkoutPage;
      const accountId = this.account.id;
      const integrationId = this.paymentMethods[this.selectedPaymentMethod].id;
      const requiredAddressTypes = ["billingAddress", "shippingAddress"].filter(
        addressType => this.requires[addressType]
      );
      const addresses = requiredAddressTypes.map(
        addressType =>
          new AddressResource({
            ...pick(this[addressType], [
              "address",
              "address2",
              "category",
              "city",
              "country",
              "state",
              "zip"
            ]),
            ...pick(this, ["firstName", "lastName"])
          })
      );
      const customer = new CustomerResource({
        ...pick(this, ["email", "firstName", "lastName", "phone"]),
        accountId,
        addresses,
        currency,
        integrationId,
        locale
      });
      const lineItems = Object.values(this.selectedPaymentOptions).map(
        paymentOptionId => new LineItemResource({ paymentOptionId })
      );
      const order = new OrderResource({
        accountId,
        checkoutPageId: this.checkoutPage.id,
        currency,
        integrationId,
        token: this.token,
        customer,
        lineItems
      });

      return order;
    },
    paymentMethods() {
      return this.checkoutPage.paymentMethods;
    },
    paymentOptionsCount() {
      const checkoutPageProducts = Object.values(
        this.checkoutPage.checkoutPageProducts
      );
      return sumBy(checkoutPageProducts, "checkoutPagePaymentOptions.length");
    },
    products() {
      return this.checkoutPage.checkoutPageProducts;
    },
    regions() {
      return this.checkoutPage.regions;
    },
    requires() {
      const {
        requireBillingAddress: billingAddress,
        requireShippingAddress: shippingAddress
      } = this.checkoutPage;
      return {
        billingAddress,
        shippingAddress
      };
    },
    sections() {
      return this.checkoutPage.sections;
    },
    supportEmail() {
      return this.account.supportEmail;
    },
    useCaptcha() {
      return this.checkoutPage.useCaptcha;
    },
    ...mapState({
      checkoutPageOptions: state => state.checkoutPages.options
    })
  },
  mixins: [stripeMixin],
  methods: {
    async applyBackendErrors(order) {
      console.log("Validation error!");

      const { refs } = this.validationObserver;
      const refNames = Object.keys(refs);

      Object.values(get(order, "customer.errors", {})).forEach(error => {
        const { attribute, fullMessage } = error;
        const fieldName = `order.customer.${camelCase(attribute)}`;
        if (refNames.includes(fieldName)) {
          this.validationObserver.refs[fieldName].applyResult({
            errors: [fullMessage], // array of string errors
            valid: false, // boolean state
            failedRules: {} // should be empty since this is a manual error.
          });
        } else {
          this.applyBaseError(fullMessage);
        }
      });

      get(order, "customer.addresses", []).forEach(address => {
        Object.values(address.errors).forEach(error => {
          this.applyBaseError(error.fullMessage);
        });
      });

      get(order, "lineItems", []).forEach(lineItem => {
        Object.values(lineItem.errors).forEach(error => {
          this.applyBaseError(error.fullMessage);
        });
      });

      Object.values(order.errors).forEach(error => {
        const { attribute, message, fullMessage } = error;
        if (
          ["customer", "lineItems"].includes(attribute) &&
          message === "can't be blank"
        ) {
          // Ignore errors about children being blank, as this is usually not the case,
          // but rather that the child has a validation error which caused it not to be saved,
          // thus appearing not to exist for the main object (`order`).
        } else {
          this.applyBaseError(fullMessage);
        }
      });

      this.goToFirstError();
    },
    async applyBaseError(error) {
      this.validationObserver.refs.base.applyResult({
        errors: [error], // array of string errors
        valid: false, // boolean state
        failedRules: {} // should be empty since this is a manual error.
      });
    },
    async applyBaseErrorAndGo(error) {
      this.applyBaseError(error);
      this.goToFirstError();
    },
    // Checks the status of the order.
    async checkStatus(id) {
      try {
        const order = (await OrderResource.find(id)).data;
        const { clientSecret, status } = order;
        console.log("checkStatus", status, order);

        if (status === "paid") {
          // Finalize the order to retrieve e.g. the thank you page url
          this.finalizeOrder(id);
        } else if (status === "requires_action") {
          // Emit an event to be fetched by the payment gateway adapter and handled.
          // Stripe will for instance launch a modal with 3D Secure verification.
          EventBus.$emit("requiresAction", id, clientSecret);
        } else if (status === "errored") {
          this.applyBaseErrorAndGo(order.error);
        }
      } catch (error) {
        this.serverError();
      }
    },
    async confirmPayment(id) {
      const order = new OrderConfirmationResource({
        ...this.orderData,
        id
      });

      console.log("Confirming order with:", order);

      try {
        await order.save();
        const { status } = order;

        if (status === "paid") {
          // Finalize the order to retrieve e.g. the thank you page url
          this.finalizeOrder(id);
        } else {
          // If the order fails to confirm, the updated status would not be returned.
          this.applyBackendErrors(order);
        }
      } catch (error) {
        console.log("Error", error);
        this.serverError();
      }
    },
    async finalizeOrder(id) {
      const orderFinalization = new OrderFinalizationResource({
        orderId: id
      });

      try {
        const success = await orderFinalization.save();
        const { amount, currency, redirectTo } = orderFinalization;

        if (success) {
          // Track the successful payment.
          this.trackSuccessfulPayment(amount, currency);

          // Redirect to the success page.
          // Make sure to use `window.top` in case th checkout page is embedded in an iframe.
          window.top.location.href = redirectTo;
        } else {
          console.log("orderFinalization", orderFinalization);
          // Unable to finalize the payment. Should this ever happen?
          const error = Object.values(orderFinalization.errors)[0];
          const errorMessage = error && error.fullMessage;
          this.applyBaseErrorAndGo(errorMessage);
        }
      } catch (error) {
        console.log("Error encountered when finalizing the order", error);
        this.serverError();
      }
    },
    createToken() {
      console.log("createToken");
      this.$emit("setLoading", true);
      EventBus.$emit("tokenize");
    },
    // Goes to an error field and emits an event to make sure it gets focused.
    goToError(errorField) {
      console.log("goToError", errorField);
      this.$emit("setLoading", false);
      VueScrollTo.scrollTo(`[name="${errorField}"]`);
      EventBus.$emit("errorDetected", errorField);
    },
    // Goes to the first error, if any.
    goToFirstError() {
      this.$emit("setLoading", false);
      // Sometimes there is a small delay between adding a new error and when it appears in `this.validationErrors`.
      // This would cause the field with the error to not get focused.
      //
      // `this.$nextTick` waits for this update, before searching for a validation error.
      this.$nextTick(() => {
        const fieldName = Object.keys(this.validationErrors).find(
          field => this.validationErrors[field].length > 0
        );

        if (fieldName) this.goToError(fieldName);
      });
    },
    //
    // Actually sends the form data to the server. Will return a token to listen for.
    //
    async sendForm() {
      console.log("sendForm");
      const order = this.orderData;
      console.log("Sending form with", order);

      try {
        const success = await order.save({
          with: [{ customer: "addresses" }, "lineItems"]
        });
        console.log("success?", success);

        if (success) {
          // Subscribe to Websocket
          // This will let us know when the order has been processed
          this.subscribeToOrderChannel(order.id);
        } else {
          // A validation error occured
          this.applyBackendErrors(order);
        }
      } catch (error) {
        console.log("Error", error);
        this.serverError();
      }
    },
    serverError() {
      console.log("serverError");
      this.applyBaseErrorAndGo(
        "Something went wrong. Please make sure that you are connected to the internet and try again."
      );
    },
    showGuaranteeNotice() {
      // eslint-disable-next-line no-alert
      alert(
        "Order Today With Confidence. Complete your order today, and if for any reason you " +
          "don't absolutely love this product, we'll refund you NO QUESTIONS ASKED."
      );
    },
    showSslExplanation() {
      // eslint-disable-next-line no-alert
      alert(
        "Order with confidence. The identity of this website has been verified by " +
          "Go Daddy Secure Certification Authority and uses 128-bit encryption"
      );
    },
    //
    // Called when hitting the "submit" button.
    //
    async submit() {
      // Clear any card field errors if PayPal was selected as the payment method.
      if (this.selectedPaymentMethod === "paypal") {
        this.errors.remove("CardNumber");
        this.errors.remove("CardExpiry");
        this.errors.remove("CardCvc");
      }

      // Check if the form is valid. We do this by checking for the presence of any errors.
      const valid = await this.validationObserver.validate();

      console.log("valid", valid);

      if (!valid) {
        this.goToFirstError();
        return false;
      }

      // Clear any form errors.
      await this.validationObserver.reset();

      // Perform captcha test if enabled and it has not been performed.
      // Triggering recaptcha again after it has been verified does nothing,
      // and the form is never sent.
      if (this.useCaptcha && !this.captchaVerified) {
        // Trigger the captcha script
        window.grecaptcha.execute();
      } else {
        // Captcha is either not enabled or has already been verified. Just generate the token.
        // Create a token we can use to process the payment.
        this.createToken();
      }

      return true;
    },
    subscribeToOrderChannel(id) {
      console.log("subscribeToOrderChannel", id);

      // Abort and show error if it takes too long for the server to respond
      // TODO: Link to a page where the customer could go to check the status of the order
      const timeout = setTimeout(() => {
        // This will trigger the `disconnected` callback
        window.App.cable.subscriptions.consumer.disconnect();
      }, 1000 * 1000);

      // Set up websocket where we listen for a response.
      window.App.order = window.App.cable.subscriptions.create(
        {
          channel: "OrderChannel",
          id
        },
        {
          connected: () => {
            // Called when the subscription is ready for use on the server
            console.log("connected");

            // If the payment was completed before we connect to the websocket, we would never
            // get notified of its status, so let's check it to make sure.
            this.checkStatus(id);
          },
          disconnected: () => {
            // Called when the subscription has been terminated by the server
            console.log("disconnected");

            this.applyBaseErrorAndGo(
              `This seems to be taking too long. Please email support@paycartpro.com and reference transaction "${id}" and we'll take a look.`
            );
          },
          received: () => {
            // Called when there's incoming data on the websocket for this channel.
            // Receives an object with the status of the order.
            console.log("received");

            // Cancel the timeout now that we received a message form the server
            clearTimeout(timeout);

            // Unsubscribe when we have received the confirmation that the backend has finished processing the order.
            window.App.order.unsubscribe();

            // Make a request to confirm the status of the payment, set session,
            // get redirect links, etc.
            this.checkStatus(id);
          }
        }
      );

      // Workaround to make sure that we call check status in tests, since the `connected` callback
      // is never called.
      this.checkStatus(id);
    },
    trackSuccessfulPayment(amount, currency) {
      try {
        // Facebook
        if (window.track_facebook === true) {
          console.log("Tracking: Facebook");
          window.fbq("track", "Purchase", {
            value: amount,
            currency
          });
        }
        // Google Adwords
        if (window.track_google_adwords === true) {
          console.log("Tracking: Google Ads");
          return this.googleReportConversion(amount);
        }
      } catch (error) {
        console.log(
          "Tracking failed. Is your browser blocking tracking scripts?",
          error.message
        );
        return false;
      }
      return true;
    },
    // Tracks a conversion for Google Adwords
    googleReportConversion(value) {
      window.goog_snippet_vars();
      // eslint-disable-next-line @typescript-eslint/camelcase
      window.google_conversion_format = "3";
      // eslint-disable-next-line @typescript-eslint/camelcase
      window.google_conversion_value = value;
      const opt = {};
      const convHandler = window.google_trackConversion;
      if (typeof convHandler === "function") {
        return convHandler(opt);
      }
      return null;
    }
  },
  store,
  created() {
    this.selectedPaymentMethod = this.defaultPaymentMethod;
  },
  mounted() {
    // Select the payment options that should be selected by default if not defined server side.
    // This would for instance be the case if there is only one product, the product is required,
    // it has only one payment option, but it is not selected by default.
    //
    // This is to ensure that if there is only one payment option, it is always selected by default,
    // preventing problems with templates hiding the ability to select payment options when there is only one.
    // this.$store.dispatch("orders/selectDefaultPaymentOptions");

    // Listen to the event called when the captcha has been verified.
    // With a valid captcha, it is now safe to create the token.
    EventBus.$on("captchaVerified", response => {
      this.captchaVerified = true;
      this.createToken();
    });

    // Listen to the confirmation event after having validated the payment with i.e. 3D Secure.
    EventBus.$on("confirmPayment", id => {
      this.confirmPayment(id);
    });

    EventBus.$on("goToFirstError", () => {
      this.goToFirstError();
    });

    // Listen to the event called when the payment requires extra authentication (i.e. 3D secure).
    // With a valid captcha, it is now safe to create the token.
    EventBus.$on("requiresAction", (id, paymentIntentClientSecret) => {
      this.handleAction(id, paymentIntentClientSecret);
    });

    // Listen to submit event from the ButtonSubmit
    EventBus.$on("submit", () => {
      this.submit();
    });

    // Listen to the tokenized event called when we have successfully tokenized the payment.
    EventBus.$on("tokenized", () => {
      this.sendForm();
    });

    // Listen to the tokenized event called when a request is sent to tokenize the payment.
    EventBus.$on("tokenizeWithStripe", cardNumberElement => {
      this.tokenizeWithStripe(cardNumberElement);
    });

    // Handle failed tokenization.
    EventBus.$on("tokenizationFailed", payload => {
      // If the tokenization of the card has failed, hide the loading indicator,
      // so that it doesn't cover up the screen.
      this.$emit("setLoading", false);
      if (payload.serverError === true) {
        this.serverError();
      } else {
        // Target the right field depending on the error code. Default to the `CardNumber`.
        const expiryErrors = [
          "expired_card",
          "incomplete_expiry",
          "invalid_expiry_month",
          "invalid_expiry_year"
        ];
        const cvcErrors = ["incomplete_cvc", "incorrect_cvc", "invalid_cvc"];
        const { code } = payload;
        let ref = "CardNumber";
        if (expiryErrors.includes(code)) {
          ref = "CardExpiry";
        } else if (cvcErrors.includes(code)) {
          ref = "CardCvc";
        }

        this.validationObserver.refs[ref].applyResult({
          errors: [payload.message], // array of string errors
          valid: false, // boolean state
          failedRules: {} // should be empty since this is a manual error.
        });

        this.goToFirstError();
      }
    });

    // Listen to the authorize event from PayPal
    EventBus.$on("paypalPaymentAuthorized", () => {
      this.$emit("setLoading", true);
      this.sendForm();
    });

    // Load any videos
    // The video player cannot be instantiated until the checkout page has been loaded & mounted.
    // There might be several video tags, so we need to get them all.
    const videoTags = document.querySelectorAll(".video-js");
    // Trying to call `videojs` on the array of tags don't work.
    // Instead, we need to loop over them.
    if (videoTags && window.videojs) {
      // eslint-disable-next-line func-names
      videoTags.forEach(function(videoTag) {
        window.videojs(videoTag);
      });
    }
  }
};
