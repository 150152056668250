<template>
  <div class="payment-options">
    <div
      v-if="singlePaymentOption && singlePaymentOptionHtml"
      class="single-payment-option"
    >
      {{ singlePaymentOptionHtml }}
    </div>
    <payment-options-product
      v-for="checkoutPageProduct in checkoutPageProductsOrdered"
      v-show="!(singlePaymentOption && singlePaymentOptionHtml)"
      :key="checkoutPageProduct.product.id"
      v-model="innerValue[checkoutPageProduct.product.id]"
      :payment-options="checkoutPageProduct.checkoutPagePaymentOptions"
      :product="checkoutPageProduct.product"
      :product-name="checkoutPageProduct.label"
      :required="checkoutPageProduct.required"
      :stacked="stacked"
    />
    <slot />
    <b-row v-if="conditionalTotalRow" no-gutters class="total-row">
      <b-col class="total-description-col">
        <span class="total-description"> {{ totalDescriptionText }}: </span>
      </b-col>
      <b-col cols="auto" class="total-amount-col">
        <span class="total-amount">
          {{ formattedTotalAmount }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { get, sortBy } from "lodash";

import Money from "utils/Money";
import PaymentOptionsProduct from "components/PaymentOptionsProduct.vue";

export default {
  components: {
    PaymentOptionsProduct
  },
  props: {
    products: {
      required: true,
      type: Array
    },
    singlePaymentOptionHtml: {
      default: undefined,
      type: String
    },
    stacked: {
      default: false,
      type: Boolean
    },
    showTotal: {
      default: "always",
      type: String
    },
    totalDescriptionText: {
      default: () =>
        window.I18n.t("views.checkout_pages.templates.common.total due today"),
      required: false,
      type: String
    },
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    innerValue: {}
  }),
  computed: {
    conditionalTotalRow() {
      if (this.showTotal === "always") return true;
      // else moreThanOne
      return this.products.length > 1;
    },
    currency() {
      return this.products[0].product.currency;
    },
    formattedTotalAmount() {
      if (typeof this.totalAmount !== "number" || !this.currency) {
        return null;
      }
      const money = new Money(this.totalAmount, this.currency);
      return money.format();
    },
    I18n() {
      return window.I18n;
    },
    checkoutPageProductsOrdered() {
      return sortBy(this.products, ["position"]);
    },
    singlePaymentOption() {
      // Check if there is more than one product
      if (this.products.length > 1) return false;

      // Check if there is more than one payment option
      if (this.products[0].checkoutPagePaymentOptions.length > 1) return false;

      // There is only a single product with a single payment option
      return true;
    },
    totalAmount() {
      const selectedCheckoutPageProducts = this.products.filter(product =>
        Object.keys(this.value).includes(product.product.id)
      );

      // Get an array containing the initial payment amounts for all selected payment options
      const amounts = selectedCheckoutPageProducts.map(checkoutPageProduct => {
        const selectedCheckoutPagePaymentOption = checkoutPageProduct.checkoutPagePaymentOptions.find(
          checkoutPagePaymentOption =>
            Object.values(this.value).includes(
              checkoutPagePaymentOption.paymentOption.id
            )
        );
        return get(
          selectedCheckoutPagePaymentOption,
          "paymentOption.initialAmount",
          0.0
        );
      });

      // Sum the amounts for each selected payment option
      return amounts.reduce((a, b) => a + b, 0.0);
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue: {
      // This is an object (nested values), which means a "deep" watcher is required:
      // https://stackoverflow.com/a/42134176/3411140
      handler(newValue) {
        // Remove entries with empty values, i.e. products that have not been selected:
        // https://stackoverflow.com/a/38750895/3411140
        // https://stackoverflow.com/a/3261380/3411140
        const filtered = Object.keys(newValue)
          .filter(key => !!newValue[key])
          .reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = newValue[key];
            return obj;
          }, {});

        // If `newValue` is replaced by a new object, it will be detected as a change, and this change handler
        // will be executed again, even if the new object has identical values to `newValue`.
        // If we returned the filtered value directly every time, it would cause an infinite loop.
        // For this reason, only returned the filtered object if any of the keys were removed.
        const filteredValue =
          Object.keys(newValue).length === Object.keys(filtered).length
            ? newValue
            : filtered;

        this.$emit("input", filteredValue);
      },
      deep: true
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  }
};
</script>
