<template>
  <ValidationProvider
    :ref="validationProviderRef"
    v-slot="{ errors, valid }"
    :name="type"
    :vid="type"
    slim
  >
    <b-form-group
      :id="type"
      :class="[klass, { required: true }]"
      :invalid-feedback="errors[0]"
      :label="label"
      :label-cols="labelCols"
      :label-cols-sm="labelColsSm"
      :label-cols-md="calculatedLabelColsMd"
      :label-cols-lg="labelColsLg"
      :label-cols-xl="labelColsXl"
      :state="valid === false ? false : null"
    >
      <component
        :is="type"
        :ref="type"
        :name="type"
        :options="cardFieldsStripeElementOptions"
        :stripe="stripePublishableKey"
        @change="handleChange"
      />
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { kebabCase, toLower, upperFirst } from "lodash";
import { ValidationProvider } from "vee-validate";
import { CardCvc, CardExpiry, CardNumber } from "vue-stripe-elements-plus";
import { mapState } from "vuex";

import EventBus from "mixins/eventBus";
import cardFieldsStripeElementOptions from "mixins/cardFieldsStripeElementOptions";

export default {
  components: {
    CardCvc,
    CardExpiry,
    CardNumber,
    ValidationProvider
  },
  props: {
    labelCols: { default: null, type: Number },
    labelColsSm: { default: null, type: Number },
    labelColsMd: { default: null, type: Number },
    labelColsLg: { default: null, type: Number },
    labelColsXl: { default: null, type: Number },
    gateway: {
      required: true,
      type: Object
    },
    name: {
      required: true,
      type: String
    },
    order: {
      required: true,
      type: Array
    },
    type: {
      required: true,
      type: String
    }
  },
  computed: {
    calculatedLabelColsMd() {
      const {
        labelCols,
        labelColsSm,
        labelColsMd,
        labelColsLg,
        labelColsXl
      } = this;

      // Allow props to override the default layout
      if (labelColsMd) return labelColsMd;

      // If any layout props have been passed, use them instead
      if (labelCols || labelColsSm || labelColsMd || labelColsLg || labelColsXl)
        return null;

      // Set label-cols to `0` to make the layout vertical, if `horizontal` is false.
      return this.horizontal ? 3 : 0;
    },
    cardFieldsStripeElementOptions() {
      if (this.locale === "nb") {
        cardFieldsStripeElementOptions.elements.locale = "no";
      } else {
        cardFieldsStripeElementOptions.elements.locale = this.locale;
      }
      return cardFieldsStripeElementOptions;
    },
    validationProviderRef() {
      return `validationProviderFor${this.type}`;
    },
    klass() {
      return kebabCase(this.type);
    },
    label() {
      if (this.labelOption === "none") return "";
      return this.name;
    },
    locale() {
      return window.I18n.locale;
    },
    stripePublishableKey() {
      return this.gateway.token;
    },
    ...mapState({
      horizontal: state => state.checkoutPages.options.layout.horizontal,
      labelOption: state => state.checkoutPages.options.fields.label
    })
  },
  mounted() {
    // Listen to `complete` events so that we can focus on the next field.
    EventBus.$on("complete", type => {
      this.handleComplete(type);
    });

    // Listen to errors on these fields so that we can focus on them.
    EventBus.$on("errorDetected", type => {
      if (type === this.type) {
        this.$refs[this.type].focus();
      }
    });
  },
  methods: {
    handleComplete(type) {
      // Now that this field is completed, focus on the next field.
      //
      // Which field is next is decided by the `order` array.
      const field = toLower(type.replace("Card", ""));
      const index = this.order.indexOf(field);

      // Only focus on the next field if there is a next field
      if (this.order.length >= index) {
        const componentName = `Card${upperFirst(this.order[index + 1])}`;
        const ref = this.$refs[componentName];

        // The field only knows about its own `ref`, so `focus()` will only be called once,
        // even though this event will be handle by all card fields.
        if (ref) ref.focus();
      }
    },
    handleChange(e) {
      if (e.error) {
        this.$refs[this.validationProviderRef].applyResult({
          errors: [e.error.message], // array of string errors
          valid: false, // boolean state
          failedRules: {} // should be empty since this is a manual error.
        });
      } else {
        this.$refs[this.validationProviderRef].reset();
      }
      if (e.complete === true) {
        // Tell the other fields that this field is completed, so that we can focus on the next one.
        EventBus.$emit("complete", this.type);
      }
    },
    isCompleted() {
      return this.completed;
    }
  }
};
</script>
