<template>
  <b-form-group
    :class="{ required: true }"
    :feedback="errors.first(type)"
    :horizontal="false"
    :label="label"
    :state="state"
  >
    <div :id="type" class="form-control form-control-braintree" />
  </b-form-group>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    }
  },
  computed: {
    label() {
      if (this.$store.getters.fieldOptions("label") === "none") return "";
      return this.name;
    },
    locale() {
      return this.$store.getters.locale;
    },
    horizontal() {
      return this.$store.state.horizontal;
    },
    required() {
      return this.validations.required;
    },
    state() {
      return this.errors.has(this.type) ? false : null;
    }
  },
  mounted() {},
  methods: {}
};
</script>
