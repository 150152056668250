<template>
  <v-input
    v-model="innerValue"
    icon="user"
    :name="I18n.t('activerecord.attributes.customer.last_name')"
    entity="customer"
    field="lastName"
    path="order.customer.lastName"
    :validations="{ required }"
  />
</template>

<script>
import inputMixin from "mixins/inputMixin";

export default {
  mixins: [inputMixin],
  computed: {
    required() {
      return (
        this.requires.billingAddress === true ||
        this.requires.shippingAddress === true
      );
    }
  }
};
</script>
