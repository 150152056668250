<template>
  <div id="minimalistic">
    <hideable
      name="header"
      :visible-by-default="true"
      :show="sections['header']"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div id="header">
              <img :src="logoImagePath" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </hideable>
    <b-container class="container-800">
      <b-row>
        <b-col cols="12">
          <div id="content">
            <hideable
              name="headline"
              :visible-by-default="true"
              :show="sections['headline']"
            >
              <editable name="headline" :content="regions['headline']">
                <h1>Create Your Free Account Now</h1>
              </editable>
            </hideable>
            <b-row id="inner-content">
              <b-col md="5">
                <input-first-name v-model="firstName" :requires="requires" />
                <input-last-name v-model="lastName" :requires="requires" />
                <input-email v-model="email" :requires="requires" />
                <input-phone v-model="phone" :requires="requires" />
                <input-billing-address
                  v-model="billingAddress"
                  :requires="requires"
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.billing address"
                      )
                    }}
                  </div>
                </input-billing-address>
                <input-shipping-address
                  v-model="shippingAddress"
                  :requires="requires"
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.shipping address"
                      )
                    }}
                  </div>
                </input-shipping-address>

                <payment-method
                  v-model="selectedPaymentMethod"
                  :payment-methods="paymentMethods"
                />

                <div
                  v-show="selectedPaymentMethod === 'credit-card'"
                  class="credit-card-area text-center"
                >
                  <img
                    src="@rails/assets/images/templates/minimalistic/cards.png"
                  />
                  <card-fields
                    :checkout-page-options="checkoutPageOptions"
                    :gateway="paymentMethods['credit-card']"
                    :selected-payment-method="selectedPaymentMethod"
                  />
                </div>

                <error-messages :errors="baseErrors" />
                <button-submit
                  block
                  size="md"
                  variant="submit"
                  :loading="loading"
                  :selected-payment-method="selectedPaymentMethod"
                  >{{
                    I18n.t("views.checkout_pages.templates.common.submit order")
                  }}</button-submit
                >
                <br />
                <br />
              </b-col>
              <b-col md="7">
                <div id="info">
                  <hideable
                    name="sidebar_headline"
                    :visible-by-default="true"
                    :show="sections['sidebar_headline']"
                  >
                    <editable
                      name="sidebar-headline"
                      :content="regions['sidebar-headline']"
                    >
                      <h3>No contracts. Cancel at any time.</h3>
                    </editable>
                  </hideable>
                  <div v-show="paymentOptionsCount > 1">
                    <payment-options
                      v-model="selectedPaymentOptions"
                      :products="products"
                    />
                  </div>
                  <div v-if="paymentOptionsCount === 1">
                    <p class="single-payment-option">
                      {{ mainProductTermsInWords }}
                    </p>
                  </div>
                  <hideable
                    name="sidebar_info"
                    :visible-by-default="true"
                    :show="sections['sidebar_info']"
                  >
                    <editable
                      name="sidebar-info"
                      :content="regions['sidebar-info']"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed commodo, erat quis aliquet dapibus, enim risus
                        ultricies sem, faucibus vehicula eros ipsum viverra
                        dolor. In hac habitasse platea dictumst. Mauris
                        pellentesque magna et quam interdum, non eleifend lectus
                        fringilla.
                      </p>
                      <p>
                        Cras at nulla vitae massa interdum lobortis. Donec non
                        mollis erat, id malesuada enim. Maecenas id fermentum
                        leo. Curabitur consectetur dictum consequat. Nulla vitae
                        justo sollicitudin, cursus eros quis, cursus orci.
                      </p>
                    </editable>
                  </hideable>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <hideable
      name="footer"
      :visible-by-default="true"
      :show="sections['footer']"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div id="footer">
              <div class="copyright">
                <editable
                  name="sidebar-header"
                  :content="regions['sidebar-header']"
                >
                  <p>
                    &copy;
                    <a href="https://paycartpro.com/">PayCartPro</a>
                    2015 - {{ new Date().getFullYear() }}
                  </p>
                </editable>
                <captcha-notice :use-captcha="useCaptcha" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </hideable>
  </div>
</template>

<script>
import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        label: "none",
        placeholder: ""
      },
      layout: {
        horizontal: false
      }
    });
  }
};
</script>

<style lang="scss">
#minimalistic {
  @import "css/bootstrap3_legacy.scss";

  // Google Fonts
  @import url(https://fonts.googleapis.com/css?family=Muli:300);

  // body
  background-color: #ecf2f6;
  // Fallback fonts for `Muli` is required, as omitting them causes a bug on iPhone 4S,
  // where the total price does not update when checking an upsell bump.
  font-family: Muli, Arial, Helvetica, sans-serif;
  font-size: 16px;

  // Bootstrap extend
  .container-800 {
    max-width: 800px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #4784bd;
  }

  h1 {
    font-size: 36px;
  }

  input,
  .country-select,
  .payment-fields,
  .selected-tag {
    font-size: 14px;
  }

  .selected-tag {
    color: #495057;
    font-weight: 300;
  }
  .vs__dropdown-toggle {
    padding-left: 2px;
  }

  .country-select .vs__dropdown-toggle,
  .form-control:not(.payment-field) {
    border: 2px solid #a6b6c5;
  }

  .form-control {
    height: 34px;
  }

  .country-select .vs__search,
  .form-control {
    &::placeholder {
      color: #999999;
    }
  }

  .btn-submit {
    background-color: #4784bd;
    border: 1px solid #4784bd;
    color: #fff;
    text-transform: uppercase;
    &:hover {
      background-color: #0c8aea;
      border: 1px solid #0c8aea;
      color: #fff;
    }
  }

  #header {
    background-color: #ffffff;
    margin: 0 -15px;
    text-align: center;
    padding: 25px;

    img {
      height: 44px;
    }
  }

  #content {
    padding: 12px 0;
    padding-top: 24px;

    h1 {
      text-align: center;
    }

    .payment-header,
    .payment-method-headline {
      color: inherit;
      font-size: inherit;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .payment-method {
      margin-bottom: 16px;
    }

    .country-select .vs__dropdown-toggle {
      border: 2px solid #a6b6c5;
    }

    #info {
      background-color: #ffffff;
      border-radius: 13px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      padding: 24px;

      h3 {
        color: #000000;
        font-size: 20px;
        margin: 0;
        margin-bottom: 25px;
        text-align: center;
      }

      .product-row {
        color: #666666;
        margin-bottom: 20px;
        margin-left: 15px;

        &:last-of-type {
          background: #ff0000;
        }

        .product-name {
          font-weight: bold;

          p {
            color: #666666;
          }
        }

        .payment-option {
          color: #000;
          margin-bottom: 5px;

          p {
            margin-bottom: 10px;
          }
        }
      }

      .total-row {
        border-top: 1px solid #666666;
        color: #666666;
        font-weight: bold;
        margin-bottom: 15px;
        margin-left: 30px;
        margin-top: -10px;
        padding-top: 5px;
      }

      .single-payment-option {
        color: #98a7b4;
        text-align: center;
        margin-top: -15px;
        margin-bottom: 10px;
      }
    }
    #inner-content {
      padding-top: 52px;

      .card-fields {
        display: flex;
        flex-wrap: wrap;

        .card-number {
          flex: 100%;
          max-width: 100%;
        }

        .card-expiry,
        .card-cvc {
          flex: 50%;
          max-width: 50%;
        }

        .card-expiry {
          padding-right: 15px;
        }
        .card-cvc {
          padding-left: 15px;
        }
      }

      .button-submit {
        font-size: 14px;
      }
    }
  }

  #footer {
    background-color: #ffffff;
    text-align: center;
    margin: 0 -15px;
    padding: 25px;
    padding-bottom: 19px; // Compensate for the extra padding-bottom added by the paragraph-tag

    a,
    p {
      color: #000;
    }
  }
}
</style>
