import checkoutPages from "./checkoutPages";
import credentials from "./credentials";
import customers from "./customers";
import global from "./global";
import orders from "./orders";

export default {
  checkoutPages,
  credentials,
  customers,
  global,
  orders
};
