import CardFieldsStripeElementInput from "components/CardFieldsStripeElementInput.vue";

export default {
  components: {
    CardFieldsStripeElementInput
  },
  computed: {
    I18n() {
      return window.I18n;
    }
  },
  props: {
    labelCols: { default: null, type: Number },
    labelColsSm: { default: null, type: Number },
    labelColsMd: { default: null, type: Number },
    labelColsLg: { default: null, type: Number },
    labelColsXl: { default: null, type: Number },
    gateway: {
      required: true,
      type: Object
    },
    order: {
      required: true,
      type: Array
    }
  }
};
