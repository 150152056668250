// actions
const actions = {
  set({ commit }, credentials) {
    commit("set", credentials);
  }
};

// mutations
const mutations = {
  set(state, credentials) {
    Object.assign(state, credentials);
  }
};

// getters
const getters = {};

// initial state
const state = {
  paypal: {},
  recaptcha: {}
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
