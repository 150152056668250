import { Model } from "@vuex-orm/core";

export default class LineItem extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "lineItems";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      order: this.belongsTo("orders", "orderId"),
      orderId: this.attr(null),
      paymentOption: this.belongsTo("paymentOptions", "paymentOptionId"),
      paymentOptionId: this.attr(null),
      total: this.attr(null),
      description: this.attr(null)
    };
  }
}
