import { MiddlewareStack, Model, SpraypaintBase } from "spraypaint";

@Model()
class ApplicationRecord extends SpraypaintBase {
  public static apiNamespace = "/api";

  // Avoid CORS and use relative paths by simply setting baseUrl to ""
  // https://www.graphiti.dev/js/installation
  public static baseUrl = "";

  public static clientApplication = "paycartpro-external-frontend";
}

// Add headers to compliant with JSON:API spec
const addJsonApiHeaders = new MiddlewareStack();
addJsonApiHeaders.beforeFilters.push((_url, options): void => {
  // eslint-disable-next-line dot-notation, no-param-reassign
  options.headers["Accept"] = "application/vnd.api+json";
  // eslint-disable-next-line no-param-reassign
  options.headers["Content-Type"] = "application/vnd.api+json";
});
ApplicationRecord.middlewareStack = addJsonApiHeaders;

// Add Rails CSRF token.
//
// Without this, rails would not allow editing the `session` in API requests,
// which is required in order to login.
//
// The document needs to be loaded before the csrf tag can be found.
document.addEventListener(
  "DOMContentLoaded",
  (): void => {
    const addCsrfToken = new MiddlewareStack();
    const CsrfTag = window.document.querySelector("meta[name=csrf-token]");
    // If no CSRF tag is present on the page, there is no CSRF token to be added
    if (CsrfTag) {
      const CsrfToken = CsrfTag.getAttribute("content");
      addCsrfToken.beforeFilters.push((_url, options): void => {
        // eslint-disable-next-line dot-notation, no-param-reassign
        options.headers["X-CSRF-TOKEN"] = CsrfToken;
      });
    }
    ApplicationRecord.middlewareStack = addCsrfToken;
  },
  false
);

export default ApplicationRecord;
