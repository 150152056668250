var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"frank-kern-low-end"}},[_c('b-container',[_c('div',{staticClass:"wrapper"},[_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},[_c('div',{attrs:{"id":"pre-headline"}},[_c('editable',{attrs:{"name":"pre-headline","content":_vm.regions['pre-headline']}},[_c('p',[_c('u',[_vm._v("SUPER LIMITED DISCOUNT:")]),_vm._v(" You Save $126.00!")])])],1),_vm._v(" "),_c('div',{attrs:{"id":"headline"}},[_c('editable',{attrs:{"name":"headline","content":_vm.regions['headline']}},[_c('h1',[_vm._v("PayCartPro SURPRISE DISCOUNT!")])])],1),_vm._v(" "),_c('div',{attrs:{"id":"copy"}},[_c('editable',{attrs:{"name":"copy","content":_vm.regions['copy']}},[_c('p',[_c('b',[_c('u',[_vm._v("Yes!")])]),_vm._v("\n                Please give me Instant Access to "+_vm._s(_vm.mainProductName)+"!\n              ")]),_vm._v(" "),_c('p',[_vm._v("\n                When I order today, I get\n                "),_c('b',[_vm._v(_vm._s(_vm.mainProductName))]),_vm._v("\n                for just "+_vm._s(_vm.mainProductTermsInWords)+".\n              ")]),_vm._v(" "),_c('p',[_c('b',[_vm._v("\n                  That's a massive savings off the regularly discounted price\n                  and\n                  "),_c('u',[_vm._v("I'm saving a lot by claiming my copy now.")])])]),_vm._v(" "),_c('p',[_vm._v("\n                I understand that if I don't like it, I can get a refund and I\n                don't even have to send anything back.\n              ")])])],1),_vm._v(" "),_c('div',{attrs:{"id":"payment-details"}},[_c('div',{staticClass:"payment-header"},[_vm._v("\n              "+_vm._s(_vm.I18n.t(
                  "views.checkout_pages.templates.common.contact information"
                ))+"\n            ")]),_vm._v(" "),_c('input-first-name',{attrs:{"requires":_vm.requires},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),_c('input-last-name',{attrs:{"requires":_vm.requires},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_vm._v(" "),_c('input-email',{attrs:{"requires":_vm.requires},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('input-phone',{attrs:{"requires":_vm.requires},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_vm._v(" "),_c('input-billing-address',{attrs:{"requires":_vm.requires},model:{value:(_vm.billingAddress),callback:function ($$v) {_vm.billingAddress=$$v},expression:"billingAddress"}},[_c('div',{staticClass:"payment-header"},[_vm._v("\n                "+_vm._s(_vm.I18n.t(
                    "views.checkout_pages.templates.common.billing address"
                  ))+"\n              ")])]),_vm._v(" "),_c('input-shipping-address',{attrs:{"requires":_vm.requires},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},[_c('div',{staticClass:"payment-header"},[_vm._v("\n                "+_vm._s(_vm.I18n.t(
                    "views.checkout_pages.templates.common.shipping address"
                  ))+"\n              ")])]),_vm._v(" "),_c('payment-method',{attrs:{"payment-methods":_vm.paymentMethods},model:{value:(_vm.selectedPaymentMethod),callback:function ($$v) {_vm.selectedPaymentMethod=$$v},expression:"selectedPaymentMethod"}},[_c('div',{staticClass:"payment-header"},[_vm._v("\n                "+_vm._s(_vm.I18n.t(
                    "views.checkout_pages.templates.common.payment method"
                  ))+"\n              ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedPaymentMethod === 'credit-card'),expression:"selectedPaymentMethod === 'credit-card'"}],staticClass:"credit-card-area"},[_c('div',{staticClass:"payment-header"},[_vm._v("\n                "+_vm._s(_vm.I18n.t(
                    "views.checkout_pages.templates.common.credit card info"
                  ))+"\n              ")]),_vm._v(" "),_c('card-fields',{attrs:{"checkout-page-options":_vm.checkoutPageOptions,"gateway":_vm.paymentMethods['credit-card'],"selected-payment-method":_vm.selectedPaymentMethod}})],1),_vm._v(" "),_c('div',{staticClass:"payment-header"},[_vm._v("\n              "+_vm._s(_vm.I18n.t(
                  "views.checkout_pages.templates.common.product purchase plan"
                ))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"payment-body"},[_c('payment-options',{attrs:{"products":_vm.products,"total-description-text":_vm.I18n.t('views.checkout_pages.templates.common.total')},model:{value:(_vm.selectedPaymentOptions),callback:function ($$v) {_vm.selectedPaymentOptions=$$v},expression:"selectedPaymentOptions"}},[_c('div',{staticClass:"payment-header"},[_vm._v("\n                  "+_vm._s(_vm.I18n.t(
                      "views.checkout_pages.templates.common.total right now"
                    ))+"\n                ")])])],1),_vm._v(" "),_c('div',{staticClass:"payment-header"},[_c('center',[_vm._v("\n                "+_vm._s(_vm.I18n.t("views.checkout_pages.templates.common.process"))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('br'),_vm._v(" "),_c('error-messages',{attrs:{"errors":_vm.baseErrors}}),_vm._v(" "),_c('button-submit',{attrs:{"bootstrap":false,"loading":_vm.loading,"selected-payment-method":_vm.selectedPaymentMethod}},[_vm._v(_vm._s(_vm.I18n.t("views.checkout_pages.templates.common.order")))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('hideable',{attrs:{"name":"credit_cards","visible-by-default":true,"show":_vm.sections['credit_cards']}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@rails/assets/images/templates/frank_kern_low_end/cards.png")}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')]),_vm._v(" "),_c('hideable',{attrs:{"name":"footer","visible-by-default":true,"show":_vm.sections['footer']}},[_c('editable',{attrs:{"name":"footer","content":_vm.regions['footer']}},[_c('p',[_c('a',{attrs:{"target":"_blank","href":"#"}},[_vm._v("Terms of Service")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":"#"}},[_vm._v("Privacy")])])]),_vm._v(" "),_c('captcha-notice',{attrs:{"use-captcha":_vm.useCaptcha}})],1)],1)],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }