<template>
  <div id="ryan-deiss">
    <b-container fluid class="container-fluid-1040">
      <hideable
        name="header"
        :visible-by-default="true"
        :show="sections['header']"
      >
        <header>
          <b-row>
            <b-col lg="8">
              <img :src="logoImagePath" class="img-fluid" />
            </b-col>
          </b-row>
        </header>
      </hideable>
      <b-row>
        <b-col md="7" xl="8" class="main">
          <h3 class="heading">
            {{ I18n.t("views.checkout_pages.templates.common.step") }} 1:
            {{
              I18n.t(
                "views.checkout_pages.templates.common.contact information"
              )
            }}
          </h3>
          <div class="fields">
            <input-first-name v-model="firstName" :requires="requires" />
            <input-last-name v-model="lastName" :requires="requires" />
            <input-email v-model="email" :requires="requires" />
            <input-phone v-model="phone" :requires="requires" />
          </div>
          <input-billing-address v-model="billingAddress" :requires="requires">
            <h3 class="heading">
              {{ I18n.t("views.checkout_pages.templates.common.step") }} 2:
              {{
                I18n.t("views.checkout_pages.templates.common.billing details")
              }}
            </h3>
          </input-billing-address>
          <input-shipping-address
            v-model="shippingAddress"
            :requires="requires"
          >
            <h3 class="heading">
              {{ I18n.t("views.checkout_pages.templates.common.step") }}
              {{ shippingStep }}:
              {{
                I18n.t("views.checkout_pages.templates.common.shipping details")
              }}
            </h3>
          </input-shipping-address>
          <h3 class="heading">
            {{ I18n.t("views.checkout_pages.templates.common.step") }}
            {{ paymentStep }}:
            {{
              I18n.t(
                "views.checkout_pages.templates.common.payment information"
              )
            }}
          </h3>
          <hideable
            name="security_notice"
            :visible-by-default="true"
            :show="sections['security_notice']"
          >
            <editable
              name="security_notice"
              :content="regions['security_notice']"
              class="security"
            >
              <h3>Security is our top priority!</h3>
              <p>
                This website utilizes some of the most advanced techniques to
                protect your information and personal data including technical,
                administrative and even physical safeguards against unauthorized
                access, misuse and improper disclosure.
              </p>
            </editable>
          </hideable>
          <b-row>
            <b-col xs="12">
              <payment-method
                v-model="selectedPaymentMethod"
                :payment-methods="paymentMethods"
              />
              <div
                v-show="selectedPaymentMethod === 'credit-card'"
                class="credit-card-area"
              >
                <b-row>
                  <b-col cols="12" sm="8" class="col-xs-8">
                    <b-row>
                      <b-col cols="8" offset-xl="4">
                        <img
                          src="@rails/assets/images/templates/ryan_deiss/creditcards1.gif"
                          height="30"
                          width="179"
                          class="cards-image mb-2"
                        />
                      </b-col>
                    </b-row>
                    <card-fields
                      :checkout-page-options="checkoutPageOptions"
                      :gateway="paymentMethods['credit-card']"
                      :selected-payment-method="selectedPaymentMethod"
                      :label-cols-xl="4"
                    />
                    <div class="what-is-cvc">
                      <small>
                        <a
                          href="#"
                          @click.prevent="
                            showCvcExplanation = !showCvcExplanation
                          "
                          >{{
                            I18n.t(
                              "views.checkout_pages.templates.common.whats this"
                            )
                          }}</a
                        >
                      </small>
                      <transition name="cvc-popup">
                        <div
                          v-if="showCvcExplanation"
                          class="cvc-popup"
                          @click="showCvcExplanation = false"
                        >
                          <img
                            src="@rails/assets/images/templates/ryan_deiss/cvc-popup.png"
                            class="cvc-popup-image"
                          />
                        </div>
                      </transition>
                    </div>
                  </b-col>
                  <b-col
                    sm="4"
                    class="align-self-center d-none d-xs-block col-xs-4"
                  >
                    <div class="lock">
                      <img
                        src="@rails/assets/images/templates/ryan_deiss/lock.jpg"
                        class="img-fluid"
                        @click="showSslExplanation"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <payment-options
            v-model="selectedPaymentOptions"
            :products="products"
          />
          <div class="text-center">
            <error-messages :errors="baseErrors" />
            <button-submit
              :loading="loading"
              :selected-payment-method="selectedPaymentMethod"
            />
          </div>
        </b-col>
        <b-col md="5" xl="4" class="sidebar">
          <hideable
            name="product_info"
            :visible-by-default="true"
            :show="sections['product_info']"
          >
            <editable
              name="sidebar-header"
              :content="regions['sidebar-header']"
            >
              <h3>{{ mainProductName }}</h3>
            </editable>
            <img
              v-if="mainProductImagePath"
              :src="mainProductImagePath"
              class="img-fluid"
            />
            <editable
              name="sidebar_bullets"
              :content="regions['sidebar_bullets']"
            >
              <ul class="small-checks">
                <li>Instant access to {{ mainProductName }}</li>
              </ul>
            </editable>
          </hideable>

          <hideable
            name="support"
            :visible-by-default="true"
            :show="sections['support']"
          >
            <editable
              name="sidebar_support"
              :content="regions['sidebar_support']"
            >
              <h3>Need help?</h3>
              <p>
                <i>Customer Support</i>
                <br />
                <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
              </p>
            </editable>
          </hideable>

          <hideable
            name="guarantee"
            :visible-by-default="true"
            :show="sections['guarantee']"
          >
            <editable
              name="sidebar_guarantee"
              :content="regions['sidebar_guarantee']"
            >
              <h3>Rest Assured</h3>
              <p>
                <i>100% Satisfaction Guaranteed</i>
              </p>
            </editable>
            <img
              src="@rails/assets/images/templates/ryan_deiss/blue-guarantee.png"
              class="img-fluid"
              @click="showGuaranteeNotice"
            />
            <hr />
          </hideable>

          <hideable
            name="privacy"
            :visible-by-default="true"
            :show="sections['privacy']"
          >
            <editable
              name="sidebar_privacy"
              :content="regions['sidebar_privacy']"
            >
              <h5>Privacy</h5>
              <p>
                We will not share or trade online information that you provide
                us (including e-mail addresses).
              </p>
              <hr />
            </editable>
          </hideable>

          <hideable
            name="sidebar_security"
            :visible-by-default="true"
            :show="sections['sidebar_security']"
          >
            <editable
              name="sidebar_security"
              :content="regions['sidebar_security']"
            >
              <h5>Security</h5>
              <p>
                All personal information you submit is encrypted and secure.
              </p>
            </editable>
          </hideable>
        </b-col>
      </b-row>
    </b-container>
    <hideable
      name="footer"
      :visible-by-default="true"
      :show="sections['footer']"
    >
      <b-container fluid class="footer-container">
        <footer class="footer">
          <editable name="footer" :content="regions['footer']">
            <p>
              &copy; paycartpro.com 2015 - {{ new Date().getFullYear() }} |
              <a target="_blank" href="#">Privacy Policy</a> |
              <a target="_blank" href="#">Terms Of Service</a> |
              <a :href="`mailto:${supportEmail}`">Customer Support</a>
            </p>
          </editable>
          <captcha-notice :use-captcha="useCaptcha" />
        </footer>
      </b-container>
    </hideable>
  </div>
</template>

<script>
import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  data() {
    return {
      showCvcExplanation: false,
      stepCounter: 0
    };
  },
  computed: {
    shippingStep() {
      return this.checkoutPage.requireBillingAddress === true ? 3 : 2;
    },
    paymentStep() {
      let step = 2;
      if (this.checkoutPage.requireBillingAddress === true) {
        step += 1;
      }
      if (this.checkoutPage.requireShippingAddress === true) {
        step += 1;
      }
      return step;
    }
  },
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        placeholder: this.I18n.t(
          "forms.order.placeholders.prefix.please enter your"
        )
      }
    });
  }
};
</script>

<style lang="scss">
#ryan-deiss {
  @import "css/bootstrap3_legacy.scss";
  @import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700");
  @import url("https://fonts.googleapis.com/css?family=Lato:400,700");

  $padding-normal: 25px;

  color: #333;
  font-family: "Lato", Helvetica, Helvetica Neue, Arial, sans-serif;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Open Sans Condensed", Arial;
    font-weight: bold;
  }

  // Bootstrap
  .container-fluid-1040 {
    max-width: 1040px;
  }

  .country-select .vs__search,
  .form-control {
    &::placeholder {
      color: #999999;
    }
  }

  .form-group {
    margin-bottom: 8px;
  }

  .form-row {
    font-size: 18px;

    .country-select,
    .form-control {
      font-size: 14px;
    }
  }

  @media (min-width: 320px) {
    .col-xs-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .col-xs-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .d-xs-block {
      display: block !important;
    }
  }

  // Animations
  .cvc-popup-enter,
  .cvc-popup-leave-to {
    height: 0%;
  }
  .cvc-popup-enter-active,
  .cvc-popup-leave-active {
    transition: height 0.5s;
  }
  .cvc-popup-enter-to,
  .cvc-popup-leave {
    height: 176px;
  }

  .form-row {
    margin: 0 10px;

    @media (min-width: 768px) {
      margin: 0 16px;
    }

    .col-form-label {
      font-weight: bold;

      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }

  // Sections

  .footer-container {
    background: #000;

    .footer {
      color: #fff;
      margin-top: 32px;
      padding: 32px;
      padding-bottom: 26px; // Compensate for the extra padding-bottom added by the paragraph-tag
      text-align: center;

      a {
        color: #fff;
      }
    }
  }

  .main {
    background: #fcfcfc;
    border: 1px solid #eee;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 32px;
    overflow: hidden;
    padding: 0;

    .card-fields {
      font-weight: bold;

      @media (min-width: 320px) {
        border-right: 1px solid #ccc;
        margin-right: -16px;
        padding-right: 16px;
      }

      .col-form-label {
        text-align: left;

        @media (min-width: 1200px) {
          text-align: right;
        }
      }

      .form-row {
        margin: 0;
      }
    }

    .payment-method,
    .credit-card-area {
      margin: 0 12px;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        margin: 0 21px;
      }
    }

    .credit-card-area {
      margin-bottom: 0;
      margin-top: 0;

      @media (min-width: 768px) {
        margin-top: 8px;
      }
    }

    .payment-method {
      margin-left: 31px;
      margin-right: 31px;

      .custom-controls-stacked {
        margin-left: 10px;
      }
    }

    .heading {
      background: #1b79c2;
      color: #fff;
      font-size: 26px;
      margin: 0;
      margin-bottom: 8px;
      padding: $padding-normal;
      padding-left: $padding-normal + 6px;
      text-shadow: 0 -1px 0 #1c3e58;
      word-break: break-word;

      @media (min-width: 768px) {
        margin-bottom: 17px;
      }

      @media (min-width: 992px) {
        margin-bottom: 40px;
      }
    }

    .fields,
    .billing-address-fields,
    .shipping-address-fields {
      margin-bottom: 23px;
      padding: 0;

      @media (min-width: 768px) {
        margin-bottom: 29px;
        padding-left: 0;
      }

      @media (min-width: 992px) {
        margin-bottom: 32px;
      }

      @media (min-width: 1200px) {
        margin-left: -16px; // Hack to match field width
      }
    }

    .lock {
      cursor: pointer;
      padding-right: 21px;
    }

    .payment-method {
      margin-bottom: 16px;
    }

    .payment-options {
      background: #f5f5f5;
      margin: 17px;
      margin-top: 16px;
      padding: 16px;

      @media (min-width: 768px) {
        margin: 26px;
      }

      .product-name {
        font-weight: bold;

        p {
          margin-bottom: 5px;
        }
      }

      .product-price-col {
        text-align: right;
      }

      .product-row {
        margin-bottom: 16px;
      }

      .total-description {
        font-size: 14px;
      }

      .total-row {
        border-top: 1px solid black;
        font-family: Tahoma, Geneva, sans-serif;
        font-weight: bold;
        padding: 16px 0;
      }
    }

    .security {
      background-color: #dcf0d8;
      margin-bottom: 8px;
      margin-top: -8px;
      padding: 24px 31px;
      padding-bottom: 10px;

      @media (min-width: 768px) {
        margin-bottom: 16px;
        margin-top: -17px;
      }

      @media (min-width: 992px) {
        margin-bottom: 26px;
        margin-top: -40px;
      }

      h3 {
        font-size: 21px;
        margin-top: 10px;
      }
    }

    .button-submit-wrapper,
    .base-errors {
      margin: 0 17px;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        margin-left: 26px;
        margin-right: 26px;
      }

      .button-submit {
        background: #83d07a;
        background: linear-gradient(
          to bottom,
          #83d07a 0%,
          #57b45c 50%,
          #4fae54 51%,
          #3ba24c 100%
        );
        border: 0;
        border-bottom: 1px solid #328441;
        border-radius: 10px;
        box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.15);
        color: #fff;
        cursor: pointer;
        font-size: 35px;
        font-weight: bold;
        padding: 13px 30px;
        text-shadow: 0 -1px 0 #328441;
        width: 100%;
        white-space: normal !important;

        @media (min-width: 576px) {
          min-width: 300px;
        }

        @media (min-width: 768px) {
          width: auto;
        }

        &:hover,
        &:active {
          background: #53a9de !important;
          background: linear-gradient(
            to bottom,
            #53a9de 0%,
            #469ad4 50%,
            #3e8fcd 51%,
            #327fc2 100%
          ) !important;
          border-bottom-color: #2c6fa3 !important;
        }

        &:active {
          box-shadow: 0 0 0 0.2rem rgba(#2c6fa3, 0.5) !important;
        }
      }
    }

    .what-is-cvc {
      margin-right: 8px;
      margin-top: -10px;
      text-align: right;

      a {
        color: #aaa;
        font-size: 12px;
        font-style: italic;
        text-decoration: none;
      }

      .cvc-popup {
        cursor: pointer;
        bottom: 15px;
        left: 15px;
        position: absolute;
        text-align: left;
        z-index: 99;
      }

      .cvc-popup-image {
        height: 100%;
        max-width: 100%;
      }
    }
  }

  .sidebar {
    padding-left: 32px;

    a {
      color: #0000ee;
      text-decoration: underline;
    }

    h3 {
      color: #1b79c2;
      font-size: 1.25rem;
    }

    .small-checks {
      list-style-image: url("~@rails/assets/images/templates/ryan_deiss/check-green.png");
      padding: 0 0 0 25px;

      li {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
