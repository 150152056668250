<template>
  <v-input
    v-model="innerValue"
    icon="building"
    :name="I18n.t('activerecord.attributes.address.city')"
    entity="shippingAddress"
    field="city"
    path="order.customer.shippingAddress.city"
    :validations="{ required }"
  />
</template>

<script>
import inputAddressMixin from "mixins/inputAddressMixin";

export default {
  mixins: [inputAddressMixin]
};
</script>
