<template>
  <ValidationProvider
    :ref="lowercaseName"
    v-slot="{ errors, valid }"
    mode="eager"
    :name="lowercaseName"
    :rules="validations"
    :vid="path"
  >
    <b-form-group
      :class="{ required: required }"
      :invalid-feedback="errors[0]"
      :label="label"
      :label-cols-md="horizontal ? 3 : 0"
      :state="valid === false ? false : null"
    >
      <b-input-group style="flex-direction: row-reverse;">
        <b-form-input
          v-model="innerValue"
          :name="path"
          :placeholder="placeholder"
          :state="valid === false ? false : null"
        />
        <b-input-group-prepend v-if="showIcon" is-text>
          <icon :name="icon" />
        </b-input-group-prepend>
      </b-input-group>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
// TODO: Check if field is marked as errored when submitting

import { upperFirst } from "lodash";
import { ValidationProvider } from "vee-validate";
import { mapState } from "vuex";

import EventBus from "mixins/eventBus";

export default {
  components: {
    ValidationProvider
  },
  props: {
    icon: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    entity: {
      required: true,
      type: String
    },
    field: {
      required: true,
      type: String
    },
    noPlaceholder: {
      default: false,
      required: false,
      type: Boolean
    },
    path: {
      required: true,
      type: String
    },
    validations: {
      default: () => ({ required: false }),
      required: false,
      type: Object
    },
    value: {
      required: true,
      type: String
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    label() {
      if (this.labelOption === "none") return "";
      return (this.required ? "* " : "") + this.name;
    },
    lowercaseName() {
      return this.name.toLowerCase();
    },
    placeholder() {
      const { placeholderOption } = this;
      if (placeholderOption === "none" || this.noPlaceholder) return "";
      return upperFirst(
        `${placeholderOption} ${this.name.toLowerCase()}`.trim()
      );
    },
    required() {
      return this.validations.required;
    },
    ...mapState("checkoutPages", {
      horizontal: state => state.options.layout.horizontal,
      labelOption: state => state.options.fields.label,
      placeholderOption: state => state.options.fields.placeholder,
      showIcon: state => state.options.fields.showIcon
    })
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  mounted() {
    // This event is emitted when the whole form is validated, one event per field with an error
    EventBus.$on("errorDetected", path => {
      // Filter by this field only
      if (path === this.path) {
        // Focus on the field.
        this.$el.querySelector("input").focus();
      }
    });
  },
  methods: {
    validateField() {
      const fieldName = this.lowercaseName;
      const provider = this.$refs[fieldName];

      // Validate the field
      return provider.validate();
    }
  }
};
</script>

<style lang="scss">
// Remove extra border to the right of where the icon would have been in Internet Explorer 11.
.input-group .input-group-prepend .input-group-text {
  border-right: none;
}
</style>

<style lang="scss" scoped>
.fa-icon {
  width: 1.25em;
}
</style>
