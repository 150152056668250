<template>
  <div id="frank-kern-low-end">
    <b-container>
      <div class="wrapper">
        <b-row>
          <b-col xs="12">
            <div id="pre-headline">
              <editable name="pre-headline" :content="regions['pre-headline']">
                <p><u>SUPER LIMITED DISCOUNT:</u> You Save $126.00!</p>
              </editable>
            </div>
            <div id="headline">
              <editable name="headline" :content="regions['headline']">
                <h1>PayCartPro SURPRISE DISCOUNT!</h1>
              </editable>
            </div>
            <div id="copy">
              <editable name="copy" :content="regions['copy']">
                <p>
                  <b>
                    <u>Yes!</u>
                  </b>
                  Please give me Instant Access to {{ mainProductName }}!
                </p>
                <p>
                  When I order today, I get
                  <b>{{ mainProductName }}</b>
                  for just {{ mainProductTermsInWords }}.
                </p>
                <p>
                  <b>
                    That's a massive savings off the regularly discounted price
                    and
                    <u>I'm saving a lot by claiming my copy now.</u>
                  </b>
                </p>
                <p>
                  I understand that if I don't like it, I can get a refund and I
                  don't even have to send anything back.
                </p>
              </editable>
            </div>
            <div id="payment-details">
              <div class="payment-header">
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.contact information"
                  )
                }}
              </div>
              <input-first-name v-model="firstName" :requires="requires" />
              <input-last-name v-model="lastName" :requires="requires" />
              <input-email v-model="email" :requires="requires" />
              <input-phone v-model="phone" :requires="requires" />
              <input-billing-address
                v-model="billingAddress"
                :requires="requires"
              >
                <div class="payment-header">
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.billing address"
                    )
                  }}
                </div>
              </input-billing-address>
              <input-shipping-address
                v-model="shippingAddress"
                :requires="requires"
              >
                <div class="payment-header">
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.shipping address"
                    )
                  }}
                </div>
              </input-shipping-address>
              <payment-method
                v-model="selectedPaymentMethod"
                :payment-methods="paymentMethods"
              >
                <div class="payment-header">
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.payment method"
                    )
                  }}
                </div>
              </payment-method>

              <div
                v-show="selectedPaymentMethod === 'credit-card'"
                class="credit-card-area"
              >
                <div class="payment-header">
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.credit card info"
                    )
                  }}
                </div>
                <card-fields
                  :checkout-page-options="checkoutPageOptions"
                  :gateway="paymentMethods['credit-card']"
                  :selected-payment-method="selectedPaymentMethod"
                />
              </div>

              <div class="payment-header">
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.product purchase plan"
                  )
                }}
              </div>

              <div class="payment-body">
                <payment-options
                  v-model="selectedPaymentOptions"
                  :products="products"
                  :total-description-text="
                    I18n.t('views.checkout_pages.templates.common.total')
                  "
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.total right now"
                      )
                    }}
                  </div>
                </payment-options>
              </div>

              <div class="payment-header">
                <center>
                  {{ I18n.t("views.checkout_pages.templates.common.process") }}
                </center>
              </div>

              <div class="text-center">
                <br />
                <error-messages :errors="baseErrors" />
                <button-submit
                  :bootstrap="false"
                  :loading="loading"
                  :selected-payment-method="selectedPaymentMethod"
                  >{{
                    I18n.t("views.checkout_pages.templates.common.order")
                  }}</button-submit
                >
                <br />
                <hideable
                  name="credit_cards"
                  :visible-by-default="true"
                  :show="sections['credit_cards']"
                >
                  <img
                    src="@rails/assets/images/templates/frank_kern_low_end/cards.png"
                    class="img-fluid"
                  />
                  <br />
                  <br />
                </hideable>
                <hideable
                  name="footer"
                  :visible-by-default="true"
                  :show="sections['footer']"
                >
                  <editable name="footer" :content="regions['footer']">
                    <p>
                      <a target="_blank" href="#">Terms of Service</a>
                      <br />
                      <br />
                      <a target="_blank" href="#">Privacy</a>
                    </p>
                  </editable>
                  <captcha-notice :use-captcha="useCaptcha" />
                </hideable>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        placeholder: "none"
      }
    });
  }
};
</script>

<style lang="scss">
#frank-kern-low-end {
  @import "css/bootstrap3_legacy.scss";

  color: #222;
  font-family: georgia, palatino, serif;

  a {
    color: #337ab7;
  }

  .wrapper {
    background: white;
    margin: 0 auto;
    margin-top: 2px;
    margin-bottom: 6px;
    padding: 30px;
    max-width: 700px;

    #pre-headline {
      color: #ff0000;
      font-size: 16px;
      font-weight: bold;
      margin: 62px 0 40px 0;
    }

    #headline h1 {
      font-size: 48px;
      line-height: 59px;
      text-align: center;
    }

    #copy p {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 13px;
    }

    #payment-details {
      font-family: "Proxima Nova", proxima-nova, sans-serif;
      font-size: 13px;
      margin: 0 auto;
      margin-top: 100px;
      max-width: 400px;

      .col-form-label {
        padding: 1px 5px;
      }

      .form-control {
        height: inherit;
        padding: 1px;
      }

      .country-select,
      .form-control {
        font-size: 13px;
      }

      .country-select .vs__dropdown-toggle,
      .form-control {
        border: 1px solid #222;
        border-radius: 0;
      }

      .form-group {
        margin-bottom: 6px;
      }

      .country-select {
        .vs__selected,
        .vs__selected-options {
          padding-left: 0;
        }

        .vs__search {
          // Match up the field's input horizontally to the other fields
          padding-left: 1px;
        }
        .vs__selected {
          margin-left: 0;
        }
        .vs__dropdown-toggle {
          padding-top: 0;
          padding-bottom: 0;
        }
        .vs__actions {
          // Align the icons better when the input field is narrow
          padding-top: 0;
        }
        .vs__selected,
        .vs__search {
          margin-top: 0;
        }
      }

      .payment-header {
        background: #000;
        color: #fff;
        margin-bottom: 5px;
        padding: 4px;
      }

      .payment-options {
        margin-top: 16px;

        .payment-header {
          margin-top: 7px;
        }
      }

      .product-name,
      .product-price {
        font-weight: bold;
      }
    }

    .payment-total-row {
      margin-bottom: 32px;
    }

    .total-row {
      padding: 5px 0;
    }
  }
}
</style>
