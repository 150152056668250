// actions
const actions = {
  startLoading({ commit }) {
    commit("setLoading", true);
  },
  stopLoading({ commit }) {
    commit("setLoading", false);
  }
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  }
};

// getters
const getters = {};

// initial state
const state = {
  loading: false
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
