import { mergeDeep } from "utils/mergeDeep";

// actions
const actions = {
  setOption({ commit }, options) {
    commit("setOption", options);
  }
};

// mutations
const mutations = {
  setOption(state, options) {
    mergeDeep(state.options, options);
  }
};

// getters
const getters = {};

// initial state
const state = {
  options: {
    editor: false,
    fields: {
      label: "attribute",
      placeholder: "none",
      showIcon: false
    },
    layout: {
      horizontal: true
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
