<template>
  <div
    v-if="visible || editor"
    v-show="visible"
    :data-visibility="visibility"
    :data-section="name"
  >
    <slot />
  </div>
</template>

<script>
import { camelCase, defaultTo } from "lodash";
import { mapState } from "vuex";

export default {
  props: {
    name: {
      required: true,
      type: String
    },
    show: {
      required: false,
      type: Boolean
    },
    visibleByDefault: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    editor() {
      return this.$store.getters.editor;
    },
    formattedName() {
      return camelCase(this.name);
    },
    visibility() {
      return this.visible ? "visible" : "hidden";
    },
    // If a checkout page section visibility has been modified, use it.
    // Otherwise, default to the checkout page defaults for this section.
    visible() {
      return defaultTo(this.show, this.visibleByDefault);
    },
    ...mapState({
      editor: state => state.checkoutPages.options.editor
    })
  }
};
</script>
