<template>
  <v-input
    v-model="innerValue"
    icon="phone"
    :name="I18n.t('activerecord.attributes.customer.phone')"
    entity="customer"
    field="phone"
    path="order.customer.phone"
  />
</template>

<script>
import inputMixin from "mixins/inputMixin";

export default {
  mixins: [inputMixin]
};
</script>
