import { Model } from "@vuex-orm/core";

export default class Customer extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "customers";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      account: this.belongsTo("accounts", "accountId"),
      accountId: this.attr(null),
      addresses: this.hasMany("addresses", "customerId"),
      email: this.attr(null),
      firstName: this.attr(null),
      lastName: this.attr(null),
      phone: this.attr(null)
    };
  }
}
