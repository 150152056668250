import { Attr, BelongsTo, Model, HasMany } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import CheckoutPage from "./CheckoutPage";
import CheckoutPagePaymentOption from "./CheckoutPagePaymentOption";
import Product from "./Product";

@Model()
class CheckoutPageProduct extends ApplicationRecord {
  public static jsonapiType = "checkout_page_products";

  @BelongsTo() public checkoutPage: CheckoutPage;

  @Attr() public checkoutPageId: string;

  @HasMany() public checkoutPagePaymentOptions: CheckoutPagePaymentOption[];

  @BelongsTo() public product: Product;

  @Attr() public productId: string;

  @Attr() public label: string;

  @Attr() public position: number;

  @Attr() public required: boolean;
}

export default CheckoutPageProduct;
