import { instance as stripe } from "vue-stripe-elements-plus";

import EventBus from "mixins/eventBus";
import removeEmptyObjectEntries from "utils/RemoveEmptyObjectEntries";

export default {
  computed: {
    stripeBillingDetails() {
      // There may or may not be any `billingAddress` available
      const address = this.billingAddress
        ? (({
            city,
            country,
            address: line1,
            address2: line2,
            // eslint-disable-next-line @typescript-eslint/camelcase
            zip: postal_code,
            state
            // eslint-disable-next-line @typescript-eslint/camelcase
          }) => ({ city, country, line1, line2, postal_code, state }))(
            this.billingAddress
          )
        : {};
      const { email, name, phone } = this;

      return {
        address,
        email,
        name,
        phone
      };
    }
  },
  methods: {
    async handleAction(id, clientSecret) {
      try {
        const { error: errorAction } = await this.handleIntentConfirmation(
          clientSecret
        );

        if (errorAction) {
          // Show error from Stripe.js in payment form
          console.log("Show error from Stripe.js in payment form", errorAction);
          this.applyBaseErrorAndGo(errorAction.message);
        } else {
          console.log(
            "Sending payment to server for confirmation verification"
          );
          // Confirm the payment on the server side
          EventBus.$emit("confirmPayment", id);
        }
      } catch (error) {
        // An error happened when trying to send the request to Stripe.
        // This would happen if e.g. the payment intent is already confirmed, etc.
        this.handleActionFailure(error, id, clientSecret);
      }
    },
    async handleActionFailure(error, id, clientSecret) {
      try {
        // Retrieve the intent to see why the confirmation failed
        const { intent } = await this.retrieveIntent(clientSecret);
        if (intent.status === "succeeded") {
          // If the payment intent has already succeeded, then the order and the payment intent might be "out of sync".
          // Send the confirmation to the server, so that the order also can be updated to reflect that the order
          // has been paid.
          EventBus.$emit("confirmPayment", id);
          return;
        }
      } catch (retrievalError) {
        this.applyBaseErrorAndGo(retrievalError.message);
        return;
      }

      this.applyBaseErrorAndGo(error.message);
    },
    async handleIntentConfirmation(clientSecret) {
      if (
        clientSecret.startsWith("pi_") ||
        clientSecret.startsWith("test_pi_")
      ) {
        return stripe.handleCardAction(clientSecret);
      }
      if (
        clientSecret.startsWith("seti_") ||
        clientSecret.startsWith("test_seti_")
      ) {
        return stripe.handleCardSetup(clientSecret);
      }

      throw new Error(`Invalid Stripe intent secret`);
    },
    async retrieveIntent(clientSecret) {
      if (
        clientSecret.startsWith("pi_") ||
        clientSecret.startsWith("test_pi_")
      ) {
        return stripe.retrievePaymentIntent(clientSecret);
      }
      if (
        clientSecret.startsWith("seti_") ||
        clientSecret.startsWith("test_seti_")
      ) {
        return stripe.retrieveSetupIntent(clientSecret);
      }

      throw new Error("Invalid Stripe intent secret");
    },
    async tokenizeWithStripe(cardNumberElement) {
      const { stripeBillingDetails } = this;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const args = { billing_details: stripeBillingDetails };

      // Stripe doesn't like it when elements in argument objects are empty,
      // so remove all empty elements (i.e. remove `phone` if no phone was given).
      removeEmptyObjectEntries(args);

      const { paymentMethod, error } = await stripe.createPaymentMethod(
        "card",
        cardNumberElement,
        args
      );

      if (error) {
        // Display error.message in UI
        console.log("createPaymentMethod - error", error);
        EventBus.$emit("tokenizationFailed", {
          serverError: false,
          code: error.code,
          message: error.message
        });
        return;
      }

      // The PaymentMethod has successfully been created
      console.log("paymentMethod", paymentMethod);

      this.token = paymentMethod.id;
      EventBus.$emit("tokenized");
    }
  }
};
