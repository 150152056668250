<template>
  <div>
    <spinner :loading="loading" />
    <ValidationObserver ref="validationObserver" v-slot="{ errors }">
      <component
        :is="templateName"
        :checkout-page="checkoutPage"
        :loading="loading"
        :validation-errors="errors"
        :validation-observer="$refs.validationObserver"
        @setLoading="handleSetLoading"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import { camelCase, upperFirst } from "lodash";
import { ValidationObserver, Validator } from "vee-validate";
import Vue from "vue";
import Icon from "vue-awesome/components/Icon.vue";

import "vue-awesome/icons";

// API
import LineItemApi from "api/LineItem.ts";
import OrderApi from "api/Order.ts";
import CheckoutPage from "api/CheckoutPage";
import CheckoutPagePaymentOption from "api/CheckoutPagePaymentOption";
import CheckoutPageProduct from "api/CheckoutPageProduct";
import OrderConfirmation from "api/OrderConfirmation";

// Stores & Busses
import store from "store";

// Templates
import BrendonBurchard from "components/templates/BrendonBurchard.vue";
import FrankKernLowEnd from "components/templates/FrankKernLowEnd.vue";
import FrankKernHighEnd from "components/templates/FrankKernHighEnd.vue";
import Material from "components/templates/Material.vue";
import Minimalistic from "components/templates/Minimalistic.vue";
import InvisibleFunnel from "components/templates/InvisibleFunnel.vue";
import RyanDeiss from "components/templates/RyanDeiss.vue";

// Components
import InputBillingAddress from "components/InputBillingAddress.vue";
import CardFields from "components/CardFields.vue";
import ErrorMessages from "components/ErrorMessages.vue";
import InputEmail from "components/InputEmail.vue";
import InputFirstName from "components/InputFirstName.vue";
import InputLastName from "components/InputLastName.vue";
import InputPhone from "components/InputPhone.vue";
import InputShippingAddress from "components/InputShippingAddress.vue";
import Hideable from "components/Hideable.vue";
import PaymentMethod from "components/PaymentMethod.vue";
import PaymentOptions from "components/PaymentOptions.vue";
import Editable from "components/Editable.vue";
import CaptchaNotice from "components/CaptchaNotice.vue";
import Spinner from "components/Spinner.vue";
import ButtonSubmit from "components/ButtonSubmit.vue";

// Make common components available in all templates
Vue.component("input-billing-address", InputBillingAddress);
Vue.component("captcha-notice", CaptchaNotice);
Vue.component("card-fields", CardFields);
Vue.component("editable", Editable);
Vue.component("error-messages", ErrorMessages);
Vue.component("input-email", InputEmail);
Vue.component("input-first-name", InputFirstName);
Vue.component("hideable", Hideable);
Vue.component("input-last-name", InputLastName);
Vue.component("payment-method", PaymentMethod);
Vue.component("payment-options", PaymentOptions);
Vue.component("input-phone", InputPhone);
Vue.component("input-shipping-address", InputShippingAddress);
Vue.component("spinner", Spinner);
Vue.component("button-submit", ButtonSubmit);

Vue.component("icon", Icon);

// Import locales for VeeValidate
const veeValidateLocales = {};
veeValidateLocales.es = require("vee-validate/dist/locale/es");
veeValidateLocales.nb = require("vee-validate/dist/locale/nb_NO");
veeValidateLocales.ru = require("vee-validate/dist/locale/ru");

const props = {
  checkoutPageSlug: {
    required: true,
    type: String
  },
  credentials: {
    required: true,
    type: Object
  },
  development: {
    default: false,
    type: Boolean
  },
  editor: {
    default: false,
    type: Boolean
  }
};

export default {
  components: {
    BrendonBurchard,
    FrankKernLowEnd,
    FrankKernHighEnd,
    Material,
    Minimalistic,
    InvisibleFunnel,
    RyanDeiss,
    ValidationObserver
  },
  props,
  data() {
    return {
      checkoutPage: {},
      loading: true
    };
  },
  computed: {
    accountId() {
      return this.checkoutPage.accountId;
    },
    templateName() {
      return upperFirst(camelCase(this.checkoutPage.templateName));
    }
  },
  async created() {
    // Store the credentials in Vuex so that they can easily be accessed by any component that need them.
    store.dispatch("credentials/set", this.credentials);

    // Set editor mode for the checkout page
    store.dispatch("checkoutPages/setOption", { editor: this.editor });

    this.checkoutPage = (await CheckoutPage.includes([
      "account",
      "checkout_page_products.product",
      "checkout_page_products.checkout_page_payment_options.payment_option"
    ])
      .where({ slug: this.checkoutPageSlug })
      .first()).data;

    // Stop loading when the checkout page has been fetched
    this.handleSetLoading(false);

    // Set locale
    const { locale } = this.checkoutPage.attributes;
    window.I18n.locale = locale;
    Validator.localize(locale, veeValidateLocales[locale]);
  },
  methods: {
    handleSetLoading(loading) {
      this.loading = loading;
      // Make `loading` variable available for system specs
      window.loading = loading;
    }
  },
  store
};
</script>

<style lang="scss">
// Bootstrap 4 focus style for Stripe Inputs & vue-select Inputs
.is-focused,
.v-select.vs--open .vs__dropdown-toggle {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.is-focused {
  &.is-invalid {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  &.is-valid {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
}
</style>
