import { Attr, HasMany, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import LineItem from "./LineItem";

@Model()
class PaymentOption extends ApplicationRecord {
  public static jsonapiType = "payment_options";

  @HasMany() public lineItems: LineItem[];

  @Attr() public initialAmount: number;

  @Attr() public secondAmount: number;
}

export default PaymentOption;
