import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import VuexORMJsonAPI from "utils/VuexORMJsonAPI";

import { BASE_URL } from "config";
import {
  Account,
  Address,
  CheckoutPage,
  CheckoutPagePaymentOption,
  CheckoutPageProduct,
  Customer,
  LineItem,
  Order,
  PaymentOption,
  Product
} from "models";
import modules from "store/modules";
// import createLogger from "../../../src/plugins/logger";

Vue.use(Vuex);

// Create a new database instance.
const database = new VuexORM.Database();

// Register Models to the database.
const models = [
  Account,
  Address,
  CheckoutPage,
  CheckoutPagePaymentOption,
  CheckoutPageProduct,
  Customer,
  LineItem,
  Order,
  PaymentOption,
  Product
];
models.forEach(model => {
  database.register(model);
});

VuexORM.use(VuexORMJsonAPI);
VuexORM.use(VuexORMAxios, {
  database,
  http: {
    baseURL: BASE_URL,
    url: "/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }
});

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    ...modules
  },
  strict: debug,
  plugins: [VuexORM.install(database)]
  // plugins: debug ? [createLogger()] : []
});
