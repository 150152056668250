<template>
  <div class="button-submit-wrapper">
    <Captcha />
    <button-paypal v-show="selectedPaymentMethod === 'paypal'" />
    <component
      :is="bootstrap ? 'b-button' : 'button'"
      v-if="selectedPaymentMethod !== 'paypal'"
      class="button-submit"
      :disabled="loading"
      :block="block"
      :size="size"
      :variant="variant"
      @click="submit"
    >
      <editable name="order-button">
        <p>
          <slot>{{
            I18n.t("views.checkout_pages.templates.common.submit order")
          }}</slot>
        </p>
      </editable>
    </component>
  </div>
</template>

<script>
import Captcha from "components/Captcha.vue";
import EventBus from "mixins/eventBus";
import ButtonPaypal from "components/ButtonPaypal.vue";

export default {
  components: {
    Captcha,
    ButtonPaypal
  },
  props: {
    block: {
      default: false,
      type: Boolean
    },
    bootstrap: {
      default: true,
      type: Boolean
    },
    loading: {
      required: true,
      type: Boolean
    },
    selectedPaymentMethod: {
      required: true,
      type: String
    },
    size: {
      default: "lg",
      type: String
    },
    variant: {
      default: "success",
      type: String
    }
  },
  computed: {
    I18n() {
      return window.I18n;
    }
  },
  methods: {
    submit() {
      EventBus.$emit("submit");
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

.button-submit {
  cursor: pointer;
}
</style>
