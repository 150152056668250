import { Attr, BelongsTo, HasMany, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Account from "./Account";
import Address from "./Address";
import Integration from "./Integration";
import Order from "./Order";
import Source from "./Source";

@Model()
class Customer extends ApplicationRecord {
  public static jsonapiType = "customers";

  @HasMany() public addresses: Address[];

  @BelongsTo() public account: Account;

  @Attr() public accountId: string;

  @BelongsTo() public integration: Integration;

  @Attr() public integrationId: string;

  @HasMany() public orders: Order[];

  @HasMany() public sources: Source[];

  @Attr() public currency: string;

  @Attr() public email: string;

  @Attr() public firstName: string;

  @Attr() public lastName: string;

  @Attr() public locale: string;

  @Attr() public phone: string;
}

export default Customer;
