<template>
  <input-country
    v-model="innerValue"
    icon="globe"
    :name="I18n.t('activerecord.attributes.address.country')"
    entity="billingAddress"
    field="country"
    path="order.customer.billingAddress.country"
    :validations="{ required }"
  />
</template>

<script>
import inputAddressMixin from "mixins/inputAddressMixin";

export default {
  mixins: [inputAddressMixin]
};
</script>
