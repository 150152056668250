import { Attr, BelongsTo, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Customer from "./Customer";

@Model()
class Address extends ApplicationRecord {
  public static jsonapiType = "addresses";

  @BelongsTo() public customer: Customer[];

  @Attr() public category: string;

  @Attr() public address: string;

  @Attr() public address2: string;

  @Attr() public city: string;

  @Attr() public country: string;

  @Attr() public firstName: string;

  @Attr() public lastName: string;

  @Attr() public state: string;

  @Attr() public zip: string;
}

export default Address;
