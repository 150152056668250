// Define base classes
const baseClasses = "form-control payment-field";

// Define default classes object
export const classes = {
  base: baseClasses,
  complete: "is-valid",
  empty: "is-empty",
  focus: "is-focused",
  invalid: "is-invalid"
};

export const style = {
  base: {
    // The default is Helvetic Neue, which is not a default Windows font.
    // As a result, the Mac and windows would use different fonts.
    //
    // Additionally, setting the font-family to `inherit`,
    // allows templates to override the font-family of the fields.
    fontFamily: "inherit",
    // Match Bootstrap's line height for form fields.
    lineHeight: 1.5
  },
  invalid: {
    color: "inherit"
  }
};

export default {
  classes,
  elements: {},
  // elements: {
  //   locale: 'nb',
  // },
  style
};
