import { Model } from "@vuex-orm/core";

export default class CheckoutPage extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "checkoutPages";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      account: this.belongsTo("accounts", "accountId"),
      accountId: this.attr(null),
      orders: this.hasMany("orders", "checkoutPageId"),
      paymentOptions: this.belongsToMany(
        "paymentOptions",
        "checkoutPagePaymentOptions",
        "checkoutPageId",
        "paymentOptionId"
      ),
      products: this.belongsToMany(
        "products",
        "checkoutPageProducts",
        "checkoutPageId",
        "productId"
      ),
      useCaptcha: this.attr(null),
      compatibleWithInvisibleFunnel: this.attr(null),
      currency: this.attr(null),
      locale: this.attr(null),
      paymentMethods: this.attr({}),
      paymentOptionOrder: this.attr([]),
      regions: this.attr({}),
      requireBillingAddress: this.attr(null),
      requireShippingAddress: this.attr(null),
      sections: this.attr({}),
      templateName: this.attr(null)
    };
  }
}
