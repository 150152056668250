import CardFieldsBraintreeHostedField from "components/CardFieldsBraintreeHostedField.vue";

export default {
  components: {
    CardFieldsBraintreeHostedField
  },
  computed: {
    I18n() {
      return window.I18n;
    }
  }
};
