import { Customer } from "models";

// actions
const actions = {
  setCurrentId({ commit }, id) {
    commit("setCurrentId", id);
  }
};

// mutations
const mutations = {
  setCurrentId(state, id) {
    state.currentId = id;
  }
};

// getters
const getters = {
  current: state => Customer.find(state.currentId),
  // eslint-disable-next-line no-shadow
  fieldValue: (state, getters, rootState, rootGetters) => path =>
    getters.current.id
};

// initial state
const state = {
  currentId: null
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
