<template>
  <v-input
    v-model="innerValue"
    icon="map-marker"
    name=""
    entity="shippingAddress"
    field="address2"
    :no-placeholder="true"
    path="order.customer.shippingAddress.address2"
  />
</template>

<script>
import inputAddressMixin from "mixins/inputAddressMixin";

export default {
  mixins: [inputAddressMixin]
};
</script>
