import { Attr, Model, HasMany } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import CheckoutPage from "./CheckoutPage";
import Order from "./Order";

@Model()
class Account extends ApplicationRecord {
  public static jsonapiType = "accounts";

  @HasMany() public checkoutPages: CheckoutPage[];

  @HasMany() public order: Order[];

  @Attr() public businessLogo: object;

  @Attr() public displayName: string;

  @Attr() public supportEmail: string;
}

export default Account;
