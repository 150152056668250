import { Attr, BelongsTo, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Order from "./Order";

@Model()
class OrderFinalization extends ApplicationRecord {
  public static jsonapiType = "order_finalizations";

  @BelongsTo() public order: Order;

  @Attr() public orderId: string;

  @Attr({ persist: false }) public amount: number;

  @Attr({ persist: false }) public currency: string;

  @Attr({ persist: false }) public redirectTo: string;
}

export default OrderFinalization;
