import { Attr, HasMany, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Order from "./Order";

@Model()
class Integration extends ApplicationRecord {
  public static jsonapiType = "integrations";

  @HasMany() public orders: Order[];

  @Attr({ persist: false }) public provider: string;

  @Attr({ persist: false }) public subject: string;

  @Attr({ persist: false }) public token: string;
}

export default Integration;
