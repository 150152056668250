import { Attr, BelongsTo, Model, HasMany } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Account from "./Account";
import CheckoutPage from "./CheckoutPage";
import Customer from "./Customer";
import LineItem from "./LineItem";
import Integration from "./Integration";

@Model()
class Order extends ApplicationRecord {
  public static jsonapiType = "orders";

  @BelongsTo() public account: Account;

  @Attr() public accountId: string;

  @BelongsTo() public checkoutPage: CheckoutPage;

  @Attr() public checkoutPageId: string;

  @BelongsTo() public customer: Customer;

  @Attr() public customerId: string;

  @BelongsTo() public integration: Integration;

  @Attr() public integrationId: string;

  @HasMany() public lineItems: LineItem[];

  @Attr({ persist: false }) public amount: number;

  @Attr({ persist: false }) public clientSecret: string;

  @Attr() public currency: string;

  @Attr({ persist: false }) public description: string;

  @Attr({ persist: false }) public error: string;

  @Attr({ persist: false }) public redirectTo: string;

  @Attr({ persist: false }) public status: string;

  @Attr() public token: string;
}

export default Order;
