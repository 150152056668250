<template>
  <div v-show="loading" class="spinner">
    <div class="spinner-element"></div>
  </div>
</template>

<script>
const props = {
  loading: {
    required: true,
    type: Boolean
  }
};

export default {
  props
};
</script>

<style lang="scss" scoped>
// Spinner shown when payment is being processed
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(150, 150, 150, 0.4);
  z-index: 2001;
  width: 100%;
  height: 100%;

  .spinner-element {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 120px;
    width: 120px;
    margin: 0px auto;
    -webkit-animation: rotation 0.6s infinite linear;
    -moz-animation: rotation 0.6s infinite linear;
    -o-animation: rotation 0.6s infinite linear;
    animation: rotation 0.6s infinite linear;
    border-left: 12px solid rgba(0, 174, 239, 0.15);
    border-right: 12px solid rgba(0, 174, 239, 0.15);
    border-bottom: 12px solid rgba(0, 174, 239, 0.15);
    border-top: 12px solid rgba(0, 174, 239, 0.8);
    border-radius: 100%;
    z-index: 1000;
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
