<template>
  <div id="frank-kern-high-end">
    <b-container fluid>
      <div id="wrapper">
        <hideable
          name="header"
          :visible-by-default="true"
          :show="sections['header']"
        >
          <b-row id="header">
            <b-col cols="12">
              <img :src="logoImagePath" />
            </b-col>
          </b-row>
        </hideable>
      </div>
    </b-container>

    <b-container fluid class="container-fluid-1025">
      <b-row>
        <b-col>
          <div id="headline">
            <editable name="pre-headline" :content="regions['pre-headline']">
              <h1 class="pre-headline">EXTREMELY LIMITED OFFER:</h1>
            </editable>
            <editable name="headline" :content="regions['headline']">
              <h1>
                Save $1,000 on {{ mainProductName }} And <br />Get Over $4,900
                In Bonus Gifts!
              </h1>
            </editable>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <!--Sidebar-->
        <b-col id="sidebar" md="5" order="1" order-md="2">
          <hideable
            name="product_info"
            :visible-by-default="true"
            :show="sections['product_info']"
          >
            <img
              v-if="mainProductImagePath"
              id="product-image"
              :src="mainProductImagePath"
            />
            <editable
              name="sidebar-headline"
              :content="regions['sidebar-headline']"
            >
              <h3>Here’s just a fraction of what you're getting...</h3>
            </editable>
            <editable
              name="sidebar-bullets"
              :content="regions['sidebar-bullets']"
            >
              <ul>
                <li>
                  QUICKSTART! Get instant access to {{ mainProductName }} and
                  get started right now!
                </li>
                <li>
                  HANDS-FREE! How To Automate Your Entire Sales Process So You
                  Can Make Sales Consistently (And predictably!).
                </li>
              </ul>
            </editable>
            <editable name="bonus-intro" :content="regions['bonus-intro']">
              <p>
                Plus, when you take advantage of this LIMITED Grand opening
                Offer, you're getting these amazing graduation gifts as well:
              </p>
            </editable>
            <editable name="bonus-bullets" :content="regions['bonus-bullets']">
              <ul class="highlight">
                <li>BONUS: {{ mainProductName }} Shipped To Your Door!</li>
                <li>BONUS: Access to my personal email address!</li>
                <li>BONUS: Automation Jump Start!</li>
              </ul>
            </editable>
          </hideable>
          <hideable
            name="support"
            :visible-by-default="true"
            :show="sections['support']"
          >
            <editable
              name="support-headline"
              :content="regions['support-headline']"
            >
              <h3>Need Help?</h3>
            </editable>
            <editable
              name="support-content"
              :content="regions['support-content']"
            >
              <h4>Customer Support</h4>
              <p>
                Email us at
                <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
              </p>
            </editable>
          </hideable>
          <hideable
            name="guarantee"
            :visible-by-default="true"
            :show="sections['guarantee']"
          >
            <editable
              name="guarantee-headline"
              :content="regions['guarantee-headline']"
            >
              <h3>Rest Assured</h3>
            </editable>
            <editable
              name="guarantee-intro"
              :content="regions['guarantee-intro']"
            >
              <h4>100% Satisfaction Guaranteed</h4>
            </editable>
            <div class="text-center">
              <img
                src="@rails/assets/images/templates/frank_kern_high_end/guarantee.png"
              />
            </div>
            <editable
              name="guarantee-content"
              :content="regions['guarantee-content']"
            >
              <p>
                Take the entire four-week class. Download everything you can
                ...and put it to work! Create a product, get a winning sales
                funnel set up, drive traffic, make sales, and have fun.
              </p>
              <p>
                If, at the end of the four weeks, you don't think this was the
                single best investment you ever made ...let me know. I'll give
                you an immediate refund, no questions asked.
              </p>
            </editable>
          </hideable>
          <hideable
            name="privacy"
            :visible-by-default="true"
            :show="sections['privacy']"
          >
            <editable
              name="privacy-headline"
              :content="regions['privacy-headline']"
            >
              <h4>Privacy</h4>
            </editable>
            <editable
              name="privacy-content"
              :content="regions['privacy-content']"
            >
              <p>
                We will not share or trade online information that you provide
                us (including e-mail addresses).
              </p>
            </editable>
          </hideable>
          <hideable
            name="security_note"
            :visible-by-default="true"
            :show="sections['security_note']"
          >
            <editable
              name="security-headline"
              :content="regions['security-headline']"
            >
              <h4>Security</h4>
            </editable>
            <editable
              name="security-content"
              :content="regions['security-content']"
            >
              <p>
                All personal information you submit is encrypted and secure.
              </p>
            </editable>
          </hideable>
          <br />
          <br />
          <br />
        </b-col>

        <!--Main Content-->
        <b-col md="7" order="2" order-md="1">
          <div id="payment-info">
            <div id="customer-fields">
              <h4>
                {{
                  I18n.t(
                    "views.checkout_pages.templates.common.billing information"
                  )
                }}
              </h4>
              <input-first-name v-model="firstName" :requires="requires" />
              <input-last-name v-model="lastName" :requires="requires" />
              <input-email v-model="email" :requires="requires" />
              <div class="vspace-15"></div>

              <input-billing-address
                v-model="billingAddress"
                :requires="requires"
              >
                <h4>
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.billing address"
                    )
                  }}
                </h4>
              </input-billing-address>
              <div class="vspace-15"></div>

              <input-shipping-address
                v-model="shippingAddress"
                :requires="requires"
              >
                <h4>
                  {{
                    I18n.t(
                      "views.checkout_pages.templates.common.shipping address"
                    )
                  }}
                </h4>
              </input-shipping-address>
              <div class="vspace-15"></div>

              <input-phone v-model="phone" :requires="requires" />
              <div class="vspace-15"></div>
              <div class="vspace-45"></div>
            </div>

            <b-row no-gutters>
              <b-col>
                <payment-method
                  v-model="selectedPaymentMethod"
                  :payment-methods="paymentMethods"
                />
              </b-col>
              <b-col v-show="selectedPaymentMethod === 'credit-card'" cols="12">
                <h4>
                  {{
                    I18n.t("views.checkout_pages.templates.common.payment info")
                  }}
                </h4>
                <card-fields
                  :checkout-page-options="checkoutPageOptions"
                  :gateway="paymentMethods['credit-card']"
                  :selected-payment-method="selectedPaymentMethod"
                  :order="['number', 'cvc', 'expiry']"
                />
                <div class="vspace-45"></div>
              </b-col>
            </b-row>

            <h4>
              {{
                I18n.t("views.checkout_pages.templates.common.payment options")
              }}
            </h4>
            <payment-options
              v-model="selectedPaymentOptions"
              :products="products"
              :total-description-text="
                I18n.t('views.checkout_pages.templates.common.total')
              "
            >
              <h4 class="order-summary-header">
                {{
                  I18n.t("views.checkout_pages.templates.common.order summary")
                }}
              </h4>
            </payment-options>

            <div id="button-submit-area">
              <b-row>
                <b-col md="8" offset-md="2">
                  <error-messages :errors="baseErrors" />
                  <button-submit
                    block
                    variant="complete-purchase"
                    :loading="loading"
                    :selected-payment-method="selectedPaymentMethod"
                    >{{
                      I18n.t(
                        "views.checkout_pages.templates.common.complete my purchase"
                      )
                    }}</button-submit
                  >
                  <br />
                  <div class="text-center encryption-notice">
                    Payment secured by 256-bit encryption
                  </div>
                  <br />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <hideable
      name="footer"
      :visible-by-default="true"
      :show="sections['footer']"
    >
      <b-container fluid>
        <b-row>
          <b-col id="footer">
            <editable name="footer" :content="regions['footer']">
              <p>
                &copy; paycartpro.com 2015 - {{ new Date().getFullYear() }} |
                <a target="_blank" href="#">Privacy Policy</a> |
                <a target="_blank" href="#">Terms Of Service</a> |
                <a :href="`mailto:${supportEmail}`">Customer Support</a>
              </p>
            </editable>
            <captcha-notice :use-captcha="useCaptcha" />
          </b-col>
        </b-row>
      </b-container>
    </hideable>
  </div>
</template>

<script>
import "font-awesome/css/font-awesome.min.css";

import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        label: "none",
        placeholder: ""
      },
      layout: {
        horizontal: false
      }
    });
  }
};
</script>

<style lang="scss">
#frank-kern-high-end {
  @import "css/bootstrap3_legacy.scss";
  @import url("//fonts.googleapis.com/css?family=Open+Sans:400italic,400,300,700,600");

  // Colors
  $header-bg: #000;

  // Body
  color: #515151;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;

  a {
    color: #337ab7;
  }

  // Bootstrap extend
  .container-fluid-1025 {
    max-width: 1025px;
  }
  .padding-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .row-no-padding {
    margin: 0;
    [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .vspace-15 {
    margin-bottom: 15px;
  }
  .vspace-30 {
    margin-bottom: 30px;
  }
  .vspace-45 {
    margin-bottom: 45px;
  }

  .button-submit-wrapper {
    @media (min-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .btn-complete-purchase {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 25px 0;
    text-align: center;
    background: #8ec337;
    color: #fff !important;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    transition: 0.25s all;
    text-decoration: none !important;

    &:hover {
      background: #a1d34e;
    }
  }
  .padding-side-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .padding-top-bottom-20 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .country-select .vs__search,
  .form-control {
    &::placeholder {
      color: #999999;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 140%;
  }

  h4,
  .payment-method-headline {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 20px;
    margin: 10px 0;
  }

  #header {
    background: $header-bg;
    padding: 30px 0;
    text-align: center;
  }

  #headline {
    padding-top: 34px;
    h1 {
      margin-top: 0;
    }
    .pre-headline {
      color: #f04b4b;
      margin-bottom: 0;
    }
  }

  #sidebar {
    h3 {
      margin: 30px 0 10px 0;
      display: block;
      color: #398ac9;
      font-size: 24px;
      font-weight: bold;
    }

    h4 {
      border-top: solid 1px #f1f1f1;
      font-style: italic;
      font-size: 16px;
      font-weight: normal;
      padding-top: 10px;
      text-transform: inherit;
    }

    ul {
      padding: 0;
    }

    li {
      font-size: 14px;
      margin-bottom: 10px;
      background-repeat: no-repeat;
      background-position: 0 -0.1em;
      margin-left: 15px;
      min-height: 30px;
    }

    ul {
      li {
        list-style-type: none;
        background-image: url("~@rails/assets/images/templates/frank_kern_high_end/check.png");
        padding-left: 2.2em;
      }
    }

    .highlight {
      li {
        background-color: #ffff00;
      }
    }
  }

  @media (min-width: 768px) {
    #payment-info {
      padding: 0 35px;
    }
  }

  #payment-info {
    .form-group {
      margin-bottom: 0;
    }
    .form-control {
      outline: none;
      color: #555;
      font-size: 14px;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      height: inherit;
      padding: 18px 20px;
      position: relative;
      z-index: 99;

      &:focus {
        border: 1px solid #52b8ec;
        // Ensure that the selected field's borders are on top,
        // (so that the field is surrounded by a blue border on all sides when selected).
        z-index: 9999;
      }
    }
    .form-control,
    .country-select {
      margin-top: -1px; // hides double borders
    }
    .country-select .vs__dropdown-toggle,
    .form-control {
      border: 1px solid rgb(233, 233, 233);
      border-radius: 0 !important;
    }
    .invalid-feedback {
      margin-bottom: 10px;
    }
    .v-select.vs--open .vs__dropdown-toggle {
      border: 1px solid #52b8ec;
      box-shadow: none;
      position: relative; // required to make z-index work
      z-index: 9999;
    }
    .vs__selected-options {
      padding-left: 13px;
    }
    .country-select {
      .vs__dropdown-toggle {
        padding-top: 0;
        padding-bottom: 0;
      }
      .vs__search,
      .vs__selected {
        padding: 17px 0;
        padding-left: 18px;
      }
      .vs__search {
        &::placeholder {
          line-height: normal;
        }
      }
      .vs__selected-options {
        padding-left: 0;
      }
    }

    .payment-method {
      margin-bottom: 45px;
    }

    // Re-order and size card fields
    .card-fields {
      display: flex;
      flex-flow: row wrap;

      .card-number {
        order: 1;
        width: 100%;
      }

      .card-cvc {
        order: 2;
        width: 100%;
      }

      .card-expiry {
        order: 3;
        width: 100%;
      }

      @media (min-width: 768px) {
        .card-number {
          width: 75%;
        }
        .card-cvc {
          width: 25%;
        }
      }
    }

    .payment-options {
      // For long prices
      word-break: break-all; // support for old browsers
      word-break: break-word;

      .product-name {
        font-size: 30px;
        font-weight: 500;
      }

      .product-price {
        display: none;
      }

      .payment-option {
        @media (min-width: 992px) {
          float: left;
          width: 50%;
        }

        .custom-control-input ~ .custom-control-label {
          background: #fcfcfc;
          border: 1px solid #eeeeee;
          color: #444444;
          cursor: pointer;
          display: block;
          font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
            sans-serif !important;
          font-size: 12px;
          font-weight: normal;
          position: relative;
          margin-bottom: 25px;
          padding: 20px 10px;
          text-align: center;
          text-transform: uppercase;
          transition: 0.15s all;

          &:hover {
            border-color: #ddd;
          }

          &:before {
            display: none;
          }

          .price {
            font-weight: 200;
            font-size: 3.4em;
            color: #8cc705;
            display: block;
            line-height: 1em;
            margin-bottom: 6px;
          }
        }

        .custom-control-input:checked ~ .custom-control-label {
          border: 1px solid #5bbae8;

          &:before {
            background: #8cc705;
            border-radius: 18px;
            box-sizing: content-box;
            color: white;
            content: "\f00c";
            display: block;
            font-family: FontAwesome;
            font-size: 18px;
            height: 26px;
            left: inherit;
            padding: 6px 0 4px 0;
            position: absolute;
            right: -15px;
            top: -15px;
            width: 36px;
            z-index: 99;
          }
        }
      }
    }

    .order-summary-header {
      border: 1px solid #f3f3f3;
      border-radius: 2px 2px 0 0;
      margin: 0;
      margin-top: 20px;
      padding: 20px 30px;
    }

    .total-row {
      background: #f3f3f3;
      border-radius: 0 0 2px 2px;
      margin-bottom: 20px;
      padding: 20px 30px;

      .total-description-col {
        align-items: flex-end;
        display: flex;
        font-weight: bold;
        justify-content: flex-end;

        .total-description {
          margin-bottom: 6px;
        }
      }

      .total-amount {
        color: #8cc705;
        font-size: 31px;
        font-weight: bold;
        padding: 0 0 0 15px;
      }
    }

    .encryption-notice {
      font-size: 12px;
    }
  }

  #footer {
    background: #515151;
    color: #fff;
    font-size: 12px;
    margin-top: 40px;
    padding: 40px 0 54px 0; // Compensate for the extra padding-bottom added by the paragraph-tag
    text-align: center;
    p {
      color: #fff;
    }
    a {
      color: #fff;

      &:hover {
        color: #dddddd;
        text-decoration: underline;
      }
    }
  }

  /* Hacks */
  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: 10px;
  }
  .credit_card_expiration_date .row {
    margin: 0;
  }
  .due-amount {
    display: none;
  }
}
</style>
