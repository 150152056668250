<template>
  <div id="invisible-funnel">
    <b-container class="container-610">
      <div class="paper">
        <b-row>
          <b-col cols="12">
            <editable
              name="pre-content"
              :content="regions['pre-content']"
            ></editable>
            <div class="h-spacer-60"></div>
            <div class="order-box">
              <editable name="headline" :content="regions['headline']">
                <h1 class="text-center">
                  "{{ mainProductName }} Registration"
                </h1>
              </editable>
              <editable name="sub-headline" :content="regions['sub-headline']">
                <p class="text-center">
                  <b>
                    Harness These 7 Genius Level Strategies To...
                    <br />Boost Your Traffic Today.
                  </b>
                </p>
              </editable>
              <editable
                name="event-details"
                :content="regions['event-details']"
              >
                <p class="text-center">
                  <b>Date:</b> Thursday November 3rd at 8:00pm PST
                  <br />
                  <b>Limits:</b> Only <strike>200</strike>,
                  <strike>146</strike>, <strike>76</strike>...
                  <br />
                  <b>EXPANDED 127 Seats Available.</b>
                </p>
              </editable>
              <editable name="bullets" :content="regions['bullets']">
                <ul>
                  <li>
                    <b
                      >Session #1: Replay Access Immediately After
                      Registration</b
                    >
                  </li>
                  <li>
                    <b>Lorem ipsum dolor sit amet</b> - Consectetur adipiscing
                    elit. Cras a justo a orci dapibus laoreet. Pellentesque
                    mollis nunc mauris.
                  </li>
                  <li>
                    <b>Nam ultrices, enim eget venenatis imperdiet</b> -
                    Pellentesque tincidunt suscipit ante in convallis. Aenean
                    fermentum tellus quis nisi laoreet dapibus!
                  </li>
                  <li>
                    <b
                      >Session #2: Replay Access Immediately After
                      Registration</b
                    >
                  </li>
                  <li>
                    <b>Lorem ipsum dolor sit amet</b> - Consectetur adipiscing
                    elit. Cras a justo a orci dapibus laoreet. Pellentesque
                    mollis nunc mauris.
                  </li>
                  <li>
                    <b>Nam ultrices, enim eget venenatis imperdiet</b> -
                    Pellentesque tincidunt suscipit ante in convallis. Aenean
                    fermentum tellus quis nisi laoreet dapibus!
                  </li>
                </ul>
              </editable>
              <editable name="understood" :content="regions['understood']">
                <p>
                  <b>
                    Yes,
                    <u>I understand that I will not be charged a dime</u>
                  </b>
                  and will ONLY be registered to have my seat reserved by
                  filling out the form below.
                </p>
              </editable>
              <div class="h-spacer-40"></div>
              <editable
                name="call-to-action"
                :content="regions['call-to-action']"
              >
                <h3>Book Your Reservation Below</h3>
              </editable>

              <div id="payment-details">
                <input-first-name v-model="firstName" :requires="requires" />
                <input-last-name v-model="lastName" :requires="requires" />
                <input-email v-model="email" :requires="requires" />
                <input-phone v-model="phone" :requires="requires" />

                <input-billing-address
                  v-model="billingAddress"
                  :requires="requires"
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.billing address"
                      )
                    }}
                  </div>
                </input-billing-address>

                <input-shipping-address
                  v-model="shippingAddress"
                  :requires="requires"
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.shipping address"
                      )
                    }}
                  </div>
                </input-shipping-address>

                <payment-method
                  v-model="selectedPaymentMethod"
                  :payment-methods="paymentMethods"
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.payment method"
                      )
                    }}
                  </div>
                </payment-method>

                <div
                  v-show="selectedPaymentMethod === 'credit-card'"
                  class="credit-card-area"
                >
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.credit card info"
                      )
                    }}
                  </div>
                  <card-fields
                    :checkout-page-options="checkoutPageOptions"
                    :gateway="paymentMethods['credit-card']"
                    :selected-payment-method="selectedPaymentMethod"
                  />
                </div>

                <div
                  v-if="compatibleWithInvisibleFunnel"
                  class="text-center if-payment-options"
                >
                  <br />
                  <p style="font-size: 16px;">
                    <payment-options
                      v-model="selectedPaymentOptions"
                      :products="products"
                      :total-description-text="
                        I18n.t(
                          'views.checkout_pages.templates.invisible_funnel.index.total charged today'
                        )
                      "
                    />
                    <b>
                      {{
                        I18n.t(
                          "views.checkout_pages.templates.invisible_funnel.index.pay only if happy",
                          { amount: formattedMainProductSecondAmount }
                        )
                      }}
                    </b>
                  </p>
                </div>
                <div v-else>
                  <div class="payment-header">
                    {{
                      I18n.t(
                        "views.checkout_pages.templates.common.payment options"
                      )
                    }}
                  </div>

                  <div class="payment-body">
                    <payment-options
                      v-model="selectedPaymentOptions"
                      :products="products"
                      :total-description-text="
                        I18n.t('views.checkout_pages.templates.common.total')
                      "
                    >
                      <div class="payment-header total-header">
                        {{
                          I18n.t(
                            "views.checkout_pages.templates.common.total right now"
                          )
                        }}
                      </div>
                    </payment-options>
                  </div>

                  <div class="payment-header"></div>
                </div>

                <div class="text-center">
                  <br />
                  <error-messages :errors="baseErrors" />
                  <button-submit
                    :bootstrap="false"
                    :loading="loading"
                    :selected-payment-method="selectedPaymentMethod"
                    >{{
                      I18n.t(
                        "views.checkout_pages.templates.invisible_funnel.index.submit"
                      )
                    }}</button-submit
                  >
                  <br />
                  <img
                    src="@rails/assets/images/templates/invisible_funnel/credit_cards.jpg"
                    class="img-fluid"
                  />
                  <br />
                  <br />
                </div>
              </div>

              <div v-if="compatibleWithInvisibleFunnel">
                <input
                  id="agree1"
                  type="checkbox"
                  value="1"
                  class="checkbox"
                  style="display: inline-block; float:left; margin-right: 5px;"
                />
                <editable name="confirm" :content="regions['confirm']">
                  <p
                    class="small"
                    v-html="
                      I18n.t(
                        'views.checkout_pages.templates.invisible_funnel.index.i understand',
                        { amount: formattedMainProductSecondAmount }
                      )
                    "
                  ></p>
                </editable>
              </div>

              <hideable
                name="security_note"
                :visible-by-default="true"
                :show="sections['security_note']"
              >
                <br />
                <br />
                <img
                  src="@rails/assets/images/templates/invisible_funnel/secure.gif"
                  style="display: inline-block; float:left; margin-right: 5px;"
                />
                <editable name="secure" :content="regions['secure']">
                  <p>
                    Safe & Secure: Your sign-up is processed on 100% secure
                    servers.
                  </p>
                </editable>
              </hideable>
            </div>

            <hideable
              name="testimonial"
              :visible-by-default="true"
              :show="sections['testimonial']"
            >
              <editable
                name="testimonial-headline"
                :content="regions['testimonial-headline']"
              >
                <h2>
                  “This Would Be Worth $1000 A Month
                  <br />If You Charged It . . . ”
                </h2>
              </editable>
              <editable
                name="testimonial-body"
                :content="regions['testimonial-body']"
              >
                <p>
                  This is amazing! Etiam blandit ex id odio sollicitudin, quis
                  vulputate orci vestibulum. Ut ultrices, est id mattis
                  hendrerit, velit neque molestie enim, sed sodales diam justo
                  quis mauris!
                </p>
                <p class="text-center">
                  Your training would be worth $1000 a month if you charged it!
                </p>
              </editable>
              <editable
                name="testimonial-signature"
                :content="regions['testimonial-signature']"
              >
                <p class="text-right">
                  <b>- Marlin Jewell</b>
                </p>
              </editable>
              <hr />
            </hideable>

            <hideable
              name="footer"
              :visible-by-default="true"
              :show="sections['footer']"
            >
              <div class="text-center">
                <editable name="footer" :content="regions['footer']">
                  <p class="small">
                    <br />
                    &copy; paycartpro.com 2015 - {{ new Date().getFullYear() }}
                    <br />
                    <a target="_blank" href="#">Terms Of Service</a> -
                    <a target="_blank" href="#">Privacy</a>
                    <br />
                  </p>
                </editable>
                <captcha-notice :use-captcha="useCaptcha" />
              </div>
            </hideable>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import Money from "utils/Money";

import templateMixin from "mixins/templateMixin";

export default {
  mixins: [templateMixin],
  computed: {
    compatibleWithInvisibleFunnel() {
      return this.checkoutPage.compatibleWithInvisibleFunnel;
    },
    formattedMainProductSecondAmount() {
      if (typeof this.mainProductSecondAmount !== "number" || !this.currency) {
        return null;
      }
      const money = new Money(this.mainProductSecondAmount, this.currency);
      return money.format();
    },
    mainProductSecondAmount() {
      return this.mainCheckoutPageProduct.checkoutPagePaymentOptions[0]
        .paymentOption.secondAmount;
    }
  },
  created() {
    this.$store.dispatch("checkoutPages/setOption", {
      fields: {
        placeholder: "none"
      }
    });
  }
};
</script>

<style lang="scss">
#invisible-funnel {
  @import "css/bootstrap3_legacy.scss";

  // Colors
  $dark-gray: #222; // dep
  $white: #fff;

  // Body
  background-color: #ccc;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 16px;
  line-height: 120%;

  // Bootstrap extend
  .container-610 {
    max-width: 610px;
  }

  .h-spacer-30 {
    margin-top: 30px;
  }
  .h-spacer-40 {
    margin-top: 40px;
  }
  .h-spacer-50 {
    margin-top: 50px;
  }
  .h-spacer-60 {
    margin-top: 60px;
  }

  .paper {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);
    background: $white;
    margin-top: 20px;
    padding: 20px;
  }

  .order-box {
    background-color: #ffff99;
    border-color: #aa0606;
    border-style: dashed;
    border-width: 5px;
    margin: 15px;
    margin-bottom: 25px;
    padding: 20px;
    padding-top: 40px;

    hr {
      display: block;
      margin-top: 24px; // 0.5em;
      margin-bottom: 0.5em;
      margin-left: auto;
      margin-right: auto;
      border-style: inset;
      border-width: 1px;
    }
    ul {
      li:not(.vs__dropdown-option) {
        margin-bottom: 18px;
      }
    }
  }

  a {
    color: #337ab7;
  }

  h1 {
    color: #aa0606;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 25px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
  .small {
    font-size: 14px;
  }

  #payment-details {
    font-size: 13px;

    .payment-header {
      background: #000000;
      color: #ffffff;
      margin-bottom: 5px;
      padding: 4px;
    }
    .payment-body {
      padding: 5px;
      .due-amount {
        display: block;
        font-weight: bold;
      }
      .form-group {
        margin-left: 0;
      }
    }

    .card-fields {
      font-size: 13px;

      .card-number,
      .card-expiry,
      .card-cvc {
        .form-row {
          align-items: center;
          display: flex;
        }
      }
    }

    .custom-control-label::before {
      background-color: #fff;
      border: 1px solid #000;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #007bff;
      border: inherit;
    }

    .form-control {
      height: inherit;
      padding: 1px;
    }

    .country-select,
    .form-control {
      font-size: 13px;
    }

    .country-select .vs__dropdown-toggle,
    .form-control {
      border: 1px solid #222;
      border-radius: 0;
    }

    .form-group {
      margin-bottom: 6px;
    }

    .country-select {
      .vs__selected,
      .vs__selected-options {
        padding-left: 0;
      }

      .vs__search {
        // Match up the field's input horizontally to the other fields
        padding-left: 1px;
      }
      .vs__selected {
        margin-left: 0;
      }
      .vs__dropdown-toggle {
        padding-top: 0;
        padding-bottom: 0;
      }
      .vs__actions {
        // Align the icons better when the input field is narrow
        padding-top: 0;
      }
      .vs__selected,
      .vs__search {
        margin-top: 0;
      }
    }

    .form-group {
      margin-bottom: 5px;
    }

    .col-form-label {
      padding: 0 5px;
    }

    .product-name,
    .product-price {
      font-weight: bold;
    }

    .payment-total {
      height: 20px;
    }

    .total-header {
      margin-left: -5px;
      margin-right: -5px;
    }

    .if-payment-options {
      .product-row {
        display: none;
      }

      .total-row {
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .total-description-col,
        .total-amount-col {
          display: inline-block;
          flex-basis: auto;
          flex-grow: 0;
          padding: 0 3px;
          width: auto;
        }

        .total-amount {
          font-weight: bold;
        }
      }
    }

    .button-submit {
      background: url("~@rails/assets/images/templates/invisible_funnel/blank_button.gif");
      background-size: 100% 100%; /* To fill the dimensions of container (button), or */
      border: 0;
      color: #000066;
      font-size: 16px;
      font-weight: bold;
      // height: 78px;
      max-width: 340px;
      padding: 0 25px; // prevent text from going outside the yellow button
      padding-bottom: 25px;
      padding-top: 25px;
      width: 100%;
    }
  }
}
</style>
