<template>
  <ValidationProvider
    ref="base"
    v-slot="{ errors, valid }"
    name="base"
    vid="base"
  >
    <!-- This span tag exist to make `ValidationProvider` happy -->
    <span :value="value" />
    <!-- The `name` attribute is used to scroll to this error -->
    <div v-if="errors" name="base" class="base-errors">
      <div v-for="(error, i) in errors" :key="i">
        <div class="alert alert-danger errors">
          <p>{{ error }}</p>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    errors: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      value: ""
    };
  }
};
</script>
