<template>
  <div id="paypal-button" class="paypal-button"></div>
</template>

<script>
// import Paypal from 'vue-paypal-checkout';
import paypal from "paypal-checkout";
import { mapState } from "vuex";

import EventBus from "mixins/eventBus";

export default {
  data() {
    return {
      style: {
        label: "checkout", // checkout | credit | pay | buynow | generic
        size: "responsive", // small | medium | large | responsive
        shape: "pill", // pill | rect
        color: "gold" // gold | blue | silver | black
      }
    };
  },
  computed: {
    ...mapState({
      client: state => state.credentials.paypal
    }),
    // orderData() {
    //   return this.$store.getters.orderData;
    // },
    env() {
      return this.$store.getters.development ? "sandbox" : "production";
    },
    locale() {
      return this.localeMap[this.$store.getters.locale];
    },
    localeMap() {
      return {
        nb: "no_NO",
        en: "en_US",
        ru: "ru_RU"
      };
    },
    paymentGateway() {
      return this.$store.getters.paymentGateway("paypal").name;
    },
    selectedPaymentMethod() {
      return this.$store.getters.selectedPaymentMethod;
    },
    setupPaypalUrl() {
      return this.$store.getters.setupUrl(this.paymentGateway);
    }
  },
  mounted() {
    console.log("mounted", "env:", this.env);

    const buttonObject = {
      // Pass in env
      env: this.env,

      // Pass in the client ids to use to create your transaction
      // on sandbox and production environments
      client: this.client,

      // Set the locale
      locale: this.locale,

      // Pass the payment details for your transaction
      // See https://developer.paypal.com/docs/api/payments/#payment_create for the expected json parameters
      payment: this.payment,

      // Set button style
      style: this.style,

      // Display a "Pay Now" button rather than a "Continue" button
      // commit: vue.commit,

      // Pass a function to be called when the customer completes the payment
      onAuthorize: this.onAuthorize,

      // Pass a function to be called when the customer cancels the payment
      onCancel: this.onCancel
    };
    // Render the button
    paypal.Button.render(buttonObject, "#paypal-button");
  },
  methods: {
    onAuthorize(data, actions) {
      console.log("onAuthorize", data, actions);
      this.$store.dispatch("setToken", data.paymentToken);
      this.$store.dispatch("setCustomerReference", data.payerID);
      EventBus.$emit("paypalPaymentAuthorized", data);
    },
    onCancel(data) {
      console.log("onCancel", data);
      this.$store.dispatch("stopLoading");
      this.$emit("paypalPaymentCancelled", data);
    },
    onError(error) {
      console.log("onError", error);
      this.$store.dispatch("stopLoading");
    },
    payment() {
      console.log("payment");
      return new paypal.Promise((resolve, reject) => {
        // Clear any card field errors if PayPal was selected as the payment method.
        if (this.selectedPaymentMethod === "paypal_classic") {
          this.errors.remove("CardNumber");
          this.errors.remove("CardExpiry");
          this.errors.remove("CardCvc");
        }

        // Check if the form is valid. We do this by checking for the presence of any errors.
        // const formIsValid = this.$validator.validateAll();
        // console.log('Is form valid?', formIsValid);
        this.$validator.validateAll().then(valid => {
          console.log("Is form valid?", valid);
          if (!valid) {
            console.log("The form contain errors!");
            EventBus.$emit("goToFirstError");
            reject("Errors found");
            return false;
          }
          // Clear any form errors.
          this.errors.clear();

          // Make an ajax call to get the Payment ID. This should call your back-end,
          // which should invoke the PayPal Payment Create api to retrieve the Payment ID.

          // When you have a Payment ID, you need to call the `resolve` method,
          // e.g `resolve(data.paymentID)`
          // Or, if you have an error from your server side, you need to call `reject`,
          // e.g. `reject(err)`
          this.$http.post(this.setupPaypalUrl, this.orderData).then(
            response => {
              console.log("payment", response.body);
              this.$store.dispatch(
                "setOrderReference",
                response.body.order_reference
              );
              resolve(response.body.payment_token);
            },
            response => {
              // error callback
              console.log("payment failed", response);
              reject(response);
            }
          );
        });
      });
    }
  }
};
</script>
