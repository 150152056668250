import { Model } from "@vuex-orm/core";

export default class CheckoutPageProduct extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "checkoutPageProducts";

  static primaryKey = ["checkoutPageId", "productId"];

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      checkoutPageId: this.attr(null),
      productId: this.attr(null)
    };
  }
}
