// Mutates and deletes empty entries from an object:
// https://stackoverflow.com/a/38340730/3411140
const removeEmpty = obj =>
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeEmpty(val);
    // eslint-disable-next-line no-param-reassign
    else if (val == null || val === "") delete obj[key];
  });

export default removeEmpty;
