import { Attr, HasMany, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import CheckoutPage from "./CheckoutPage";

@Model()
class Product extends ApplicationRecord {
  public static jsonapiType = "products";

  @HasMany() public checkoutPages: CheckoutPage[];

  @Attr() public currency: string;

  @Attr() public image: object;

  @Attr() public name: string;
}

export default Product;
