<template>
  <b-form-checkbox-group v-model="innerValue" stacked>
    <b-form-checkbox
      v-for="checkoutPagePaymentOption in paymentOptions"
      :key="checkoutPagePaymentOption.paymentOption.id"
      :value="checkoutPagePaymentOption.paymentOption.id"
      :disabled="editor"
      class="payment-option"
    >
      <span v-html="checkoutPagePaymentOption.label" />
    </b-form-checkbox>
  </b-form-checkbox-group>
</template>

<script>
export default {
  props: {
    paymentOptions: {
      required: true,
      type: Array
    },
    productId: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: String
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    editor() {
      return this.$store.getters.editor;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      // Ensure that only one option can be selected at the same time
      if (newValue.length > 1) this.innerValue = [newValue.slice(-1)[0]];

      // `newValue` contains an `Array`, but the emitted input event is expected to always return a `String`.
      // If the array is empty, i.e. no payment option is selected, `newValue[0]` would be `undefined`,
      // which is why we need to return an empty string instead.
      this.$emit("input", newValue[0] || "");
    },
    // Handles external model changes.
    value(newValue) {
      // `value` is a String, but `innerValue` needs to contain an `Array`
      this.innerValue = Array.isArray(newValue) ? newValue : [newValue];
    }
  }
};
</script>
