import humps from "humps";
import { camelCase } from "lodash";
import pluralize from "pluralize";

//
// Contains a plugin for Vuex ORM to support JSON::API input
//

const insertJsonObject = (insertFunction, state, item) => {
  const { attributes, id, relationships, type } = item;

  // The keys for the attributes may not be in camelCase, so they need to be converted
  const payload = {
    data: {
      id,
      ...humps.camelizeKeys(attributes)
    },
    entity: pluralize(camelCase(type)),
    result: {}
  };

  // Only loop through relationships if there are any
  if (typeof relationships === "object" && relationships !== null) {
    // Loop through the relationships
    Object.entries(relationships).forEach(([key, value]) => {
      // "Has many"-relationships are given as an array of objects,
      // while "belong to"-relationships are given as an object.
      if (value.data.constructor === Array) {
        // This is a "has many"-relationship.
        payload.data[pluralize(camelCase(key))] = value.data;
      } else {
        // This is a "belongs to"-relationship.
        // This relationship can be given as a foriegn-key on the main object.
        payload.data[camelCase(value.data.type)] = { id: value.data.id };
      }
    });
  }

  insertFunction(state, payload);
};

const vuexORMJsonAPI = {
  // `components` contains Vuex ORM objects such as Model and Query.
  // The plugin author can then extend those objects to add
  // whatever features it needs.
  install(components, options) {
    // Store the original method to be called later with the updated payload
    const superInsertOrUpdate = components.RootMutations.insertOrUpdate;

    // Overwrite the `insertOrUpdate` mutation method
    // eslint-disable-next-line no-param-reassign
    components.RootMutations.insertOrUpdate = (state, payload) => {
      const { data, included } = payload.data;

      // Add each of the main query objects
      if (data.constructor === Array) {
        data.forEach(item => {
          insertJsonObject(superInsertOrUpdate, state, item);
        });
      } else {
        insertJsonObject(superInsertOrUpdate, state, data);
      }

      // Add the `included`-objects, that are related to the main query objects
      included.forEach(item => {
        insertJsonObject(superInsertOrUpdate, state, item);
      });
    };
  }
};

export default vuexORMJsonAPI;
