<template>
  <ValidationProvider
    :ref="lowercaseName"
    v-slot="{ errors, valid }"
    mode="eager"
    :name="lowercaseName"
    :rules="validations"
    :vid="path"
  >
    <b-form-group
      :class="{ required: required }"
      :invalid-feedback="errors[0]"
      :label="label"
      :label-cols-md="horizontal ? 3 : 0"
      :state="valid === false ? false : null"
    >
      <div style="position: relative;">
        <vue-select
          v-model="innerValue"
          class="country-select"
          :options="options"
          :name="path"
          :placeholder="placeholder"
          :reduce="country => country['value']"
        />
        <icon v-if="showIcon" class="icon" :name="icon" />
      </div>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { sortBy, upperFirst } from "lodash";
import { ValidationProvider } from "vee-validate";
import VueSelect from "vue-select";
import { mapState } from "vuex";
import * as i18nIsoCountries from "i18n-iso-countries/entry-node";

export default {
  components: {
    ValidationProvider,
    VueSelect
  },
  props: {
    entity: {
      required: true,
      type: String
    },
    field: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    path: {
      required: true,
      type: String
    },
    validations: {
      default: () => ({ required: false }),
      required: false,
      type: Object
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    label() {
      if (this.labelOption === "none") return "";
      return (this.required ? "* " : "") + this.name;
    },
    locale() {
      return window.I18n.locale;
    },
    options() {
      const countriesObject = i18nIsoCountries.getNames(this.locale);
      const countryOptions = Object.keys(countriesObject).map(key => ({
        label: countriesObject[key],
        value: key
      }));
      return sortBy(countryOptions, "label");
    },
    placeholder() {
      const { placeholderOption } = this;
      if (placeholderOption === "none") return "";
      return upperFirst(
        `${placeholderOption} ${this.name.toLowerCase()}`.trim()
      );
    },
    placeholderPrefix() {
      return window.I18n.t("forms.order.placeholders.prefix.please enter your");
    },
    required() {
      return this.validations.required;
    },
    lowercaseName() {
      return this.name.toLowerCase();
    },
    ...mapState("checkoutPages", {
      horizontal: state => state.options.layout.horizontal,
      labelOption: state => state.options.fields.label,
      placeholderOption: state => state.options.fields.placeholder,
      showIcon: state => state.options.fields.showIcon
    })
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      this.$emit("input", newValue || "");
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue || "";
    }
  }
};
</script>

<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

$vs-colors: (
  lightest: rgba(60, 60, 60, 0.26),
  light: rgba(60, 60, 60, 0.5),
  dark: $input-color,
  darkest: $input-color
) !default;

@import "vue-select/src/scss/vue-select.scss";

.country-select {
  background-color: #fff;
  color: $input-color;
  font-size: inherit;

  // Allow colors to be overridden
  .vs__dropdown-menu,
  .vs__dropdown-option:not(.vs__dropdown-option--highlight),
  .vs__search,
  .vs__selected {
    color: inherit;
  }
  .vs__search::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Match Bootstrap padding
  .vs__dropdown-toggle {
    padding-top: 2px;
    padding-bottom: 6px;
  }
  .vs__selected-options {
    padding-left: 4px;
  }

  // Fix placeholder position when searching and an option is already selected
  .vs__selected {
    align-self: center;
  }
}

/* TODO: Extract this style to the main css style sheet */
.is-invalid .vs__dropdown-toggle {
  border-color: #dc3545;
}

.is-valid .vs__dropdown-toggle {
  border-color: #28a745;
}

ul.dropdown-menu li a {
  color: #333 !important;
}
</style>

<style lang="scss" scoped>
.icon {
  margin-left: 12px;
  position: absolute;
  top: 40%;
  width: 1.25em;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ styles */
  .icon {
    display: none;
  }
}
</style>
