<template>
  <v-input
    v-model="innerValue"
    icon="envelope"
    :name="I18n.t('activerecord.attributes.customer.email')"
    entity="customer"
    field="email"
    path="order.customer.email"
    :validations="{ required: true, email: true }"
  />
</template>

<script>
import inputMixin from "mixins/inputMixin";

export default {
  mixins: [inputMixin]
};
</script>
