<template>
  <braintree-hosted-field
    :name="I18n.t('simple_form.labels.defaults.card_expiration_date')"
    type="card-expiry"
  />
</template>

<script>
import cardMixin from "mixins/cardFieldsBraintreeMixin";

export default {
  mixins: [cardMixin]
};
</script>
