import { Model } from "@vuex-orm/core";

export default class Address extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "addresses";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      customer: this.belongsTo("customers", "customerId"),
      customerId: this.attr(null),
      category: this.attr(null),
      firstName: this.attr(null),
      lastName: this.attr(null),
      address: this.attr(null),
      address2: this.attr(null),
      city: this.attr(null),
      state: this.attr(null),
      country: this.attr(null),
      zip: this.attr(null)
    };
  }
}
