// Required polyfills to support IE11:
// https://github.com/rails/webpacker/blob/master/docs/es6.md#babel
import "core-js";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";

import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import humps from "humps";
import "jquery";
import VeeValidate from "vee-validate";
import Vue from "vue";
import VueResource from "vue-resource";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "utils/sentry";

import App from "views/orders/New.vue";

Vue.use(BootstrapVue);
Vue.use(VeeValidate, { inject: false });
Vue.use(VueResource);

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("checkout-form");

  if (element != null) {
    // Set CSRF Token for every Vue Resource requests
    const tokenTag = document.querySelector('meta[name="csrf-token"]');
    if (tokenTag) {
      Vue.http.headers.common["X-CSRF-Token"] = tokenTag.getAttribute(
        "content"
      );
      axios.defaults.headers.common["X-CSRF-TOKEN"] = tokenTag.getAttribute(
        "content"
      );
    }

    // Maintain a global counter for active requests
    // see: spec/support/system/wait_for_requests.rb
    Vue.http.interceptors.push((request, next) => {
      window.activeVueResources = window.activeVueResources || 0;
      window.activeVueResources += 1;

      next(() => {
        window.activeVueResources -= 1;
      });
    });

    const props = humps.camelizeKeys(JSON.parse(element.dataset.checkout));

    console.log("props", props);

    const checkout = new Vue({
      render(h) {
        return h(App, {
          props
        });
      }
    });
    checkout.$mount(element);
  }
});
