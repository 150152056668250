import { Attr, BelongsTo, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Order from "./Order";
import PaymentOption from "./PaymentOption";

@Model()
class LineItem extends ApplicationRecord {
  public static jsonapiType = "line_items";

  @BelongsTo() public order: Order;

  @BelongsTo() public paymentOption: PaymentOption;

  @Attr() public paymentOptionId: string;

  @Attr({ persist: false }) public description: string;

  @Attr({ persist: false }) public total: number;
}

export default LineItem;
