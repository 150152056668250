<template>
  <card-fields-stripe-element-input
    :gateway="gateway"
    :name="I18n.t('simple_form.labels.defaults.card_number')"
    :order="order"
    type="CardNumber"
    :label-cols="labelCols"
    :label-cols-sm="labelColsSm"
    :label-cols-md="labelColsMd"
    :label-cols-lg="labelColsLg"
    :label-cols-xl="labelColsXl"
  />
</template>

<script>
import cardMixin from "mixins/cardFieldsStripeMixin";

export default { mixins: [cardMixin] };
</script>
