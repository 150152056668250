<template>
  <v-input
    v-model="innerValue"
    icon="map-marker"
    :name="I18n.t('activerecord.attributes.address.address')"
    entity="shippingAddress"
    field="address"
    path="order.customer.shippingAddress.address"
    :validations="{ required }"
  />
</template>

<script>
import inputAddressMixin from "mixins/inputAddressMixin";

export default {
  mixins: [inputAddressMixin]
};
</script>
