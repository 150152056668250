<template>
  <div v-if="required" class="billing-address-area">
    <slot />
    <div class="billing-address-fields">
      <input-billing-address-address
        v-model="innerValue.address"
        :required="true"
      />
      <input-billing-address-address2
        v-model="innerValue.address2"
        :required="false"
      />
      <input-billing-address-zip v-model="innerValue.zip" :required="true" />
      <input-billing-address-city v-model="innerValue.city" :required="true" />
      <input-billing-address-state
        v-model="innerValue.state"
        :required="true"
      />
      <input-billing-address-country
        v-model="innerValue.country"
        :required="true"
      />
    </div>
  </div>
</template>

<script>
import InputBillingAddressAddress from "components/InputBillingAddressAddress.vue";
import InputBillingAddressAddress2 from "components/InputBillingAddressAddress2.vue";
import InputBillingAddressCity from "components/InputBillingAddressCity.vue";
import InputBillingAddressCountry from "components/InputBillingAddressCountry.vue";
import InputBillingAddressState from "components/InputBillingAddressState.vue";
import InputBillingAddressZip from "components/InputBillingAddressZip.vue";

const props = {
  requires: {
    required: true,
    type: Object
  },
  value: {
    required: true,
    type: Object
  }
};

export default {
  components: {
    InputBillingAddressAddress,
    InputBillingAddressAddress2,
    InputBillingAddressCity,
    InputBillingAddressCountry,
    InputBillingAddressState,
    InputBillingAddressZip
  },
  props,
  data: () => ({
    innerValue: {
      address: "",
      address2: "",
      category: "billing",
      city: "",
      country: "",
      state: "",
      zip: ""
    }
  }),
  computed: {
    required() {
      return this.requires.billingAddress === true;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue: {
      // This is an object (nested values), which means a "deep" watcher is required:
      // https://stackoverflow.com/a/42134176/3411140
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  }
};
</script>
