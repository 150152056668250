<template>
  <div>
    <card-fields-braintree-number />
    <card-fields-braintree-expiry />
    <card-fields-braintree-cvc />
  </div>
</template>

<script>
import Braintree from "braintree-web";

import EventBus from "mixins/eventBus";
import CardFieldsBraintreeCvc from "components/CardFieldsBraintreeCvc.vue";
import CardFieldsBraintreeExpiry from "components/CardFieldsBraintreeExpiry.vue";
import CardFieldsBraintreeNumber from "components/CardFieldsBraintreeNumber.vue";

export default {
  components: {
    CardFieldsBraintreeCvc,
    CardFieldsBraintreeExpiry,
    CardFieldsBraintreeNumber
  },
  computed: {
    braintreeClientToken() {
      return this.$store.getters.braintreeClientToken;
    }
  },
  created() {
    // Create an instance of Braintree and hosted fields.
    this.createBT();

    // Setup listeners
    EventBus.$on("tokenize", () => {
      this.tokenizeHF();
    });
  },
  methods: {
    createBT() {
      Braintree.client.create(
        {
          authorization: this.braintreeClientToken
        },
        (err, clientInstance) => {
          if (err) {
            console.error(err);
            return;
          }

          this.clientInstance = clientInstance;
          this.createHF();
        }
      );
    },
    createHF() {
      Braintree.hostedFields.create(
        {
          client: this.clientInstance,
          styles: {
            input: {
              "font-size": "16px",
              color: "#3a3a3a"
            },
            ":focus": {
              color: "black"
            }
          },
          fields: {
            number: {
              selector: "#card-number",
              placeholder: "4111 1111 1111 1111"
            },
            cvv: {
              selector: "#card-cvc",
              placeholder: "123"
            },
            expirationDate: {
              selector: "#card-expiry",
              placeholder: "10/2022"
            }
          }
        },
        (hostedFieldsErr, hostedFieldsInstance) => {
          if (hostedFieldsErr) {
            console.error(hostedFieldsErr);
            return;
          }

          this.hostedFieldsInstance = hostedFieldsInstance;
        }
      );
    },
    tokenizeHF() {
      this.hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
        if (tokenizeErr) {
          this.errorMessage = `There was an error tokenizing! Message: ${tokenizeErr.message}`;
          return;
        }
        this.tokenizePayload = payload;
        this.$store.dispatch("setToken", payload.nonce);
        EventBus.$emit("tokenized");
      });
    }
  }
};
</script>

<style lang="scss">
.form-control-braintree {
  height: 36px;

  &.braintree-hosted-fields-focused {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.braintree-hosted-fields-invalid {
    border-color: #dc3545;

    &.braintree-hosted-fields-focused {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  &.braintree-hosted-fields-valid {
    border-color: #28a745;

    &.braintree-hosted-fields-focused {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }
  }
}
</style>
