import { Model } from "@vuex-orm/core";

export default class Order extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "orders";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      account: this.belongsTo("accounts", "accountId"),
      accountId: this.attr(null),
      checkoutPage: this.belongsTo("checkoutPages", "checkoutPageId"),
      checkoutPageId: this.attr(null),
      customer: this.belongsTo("customers", "customerId"),
      customerId: this.attr(null),
      integrationId: this.attr(null),
      lineItems: this.hasMany("lineItems", "orderId"),
      amount: this.attr(null),
      currency: this.attr(null),
      description: this.attr(null),
      status: this.attr(null),
      token: this.attr(null)
    };
  }
}
