import { Attr, BelongsTo, Model, HasMany } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import Account from "./Account";
import CheckoutPageProduct from "./CheckoutPageProduct";
import Order from "./Order";

@Model()
class CheckoutPage extends ApplicationRecord {
  public static jsonapiType = "checkout_pages";

  @BelongsTo() public account: Account;

  @HasMany() public checkoutPageProducts: CheckoutPageProduct[];

  @HasMany() public orders: Order;

  @Attr() public compatibleWithInvisibleFunnel: boolean;

  @Attr() public currency: string;

  @Attr() public locale: string;

  @Attr() public paymentMethods: object;

  @Attr() public paymentOptionOrder: object;

  @Attr() public regions: object;

  @Attr() public requireBillingAddress: boolean;

  @Attr() public requireShippingAddress: boolean;

  @Attr() public sections: object;

  @Attr() public slug: string;

  @Attr() public templateName: string;

  @Attr() public useCaptcha: boolean;
}

export default CheckoutPage;
