<template>
  <div v-if="paymentMethods.length > 1" class="payment-method">
    <slot>
      <h5 v-if="showHeadline" class="payment-method-headline">
        {{ I18n.t("views.checkout_pages.templates.common.payment method") }}
      </h5>
    </slot>
    <b-form-radio-group v-model="innerValue" stacked>
      <b-form-radio
        v-if="paymentMethods.includes('creditCard')"
        value="creditCard"
      >
        <icon name="credit-card-alt" />
        {{ I18n.t("views.checkout_pages.templates.common.credit card") }}
      </b-form-radio>
      <b-form-radio v-if="paymentMethods.includes('paypal')" value="paypal">
        <icon name="paypal" />
        PayPal
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    paymentMethods: {
      required: true,
      type: Object
    },
    showHeadline: {
      default: true,
      required: false,
      type: Boolean
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    I18n() {
      return window.I18n;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-radio {
  margin-bottom: 8px;
}
</style>
