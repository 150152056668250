<template>
  <div v-if="useCaptcha">
    <small
      >This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </small>
  </div>
</template>

<script>
export default {
  props: {
    useCaptcha: {
      required: true,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
small,
a {
  font-size: 10px !important;
}
</style>

<style lang="scss">
// Hide reCAPTCHA badge
.grecaptcha-badge {
  visibility: hidden;
}
</style>
