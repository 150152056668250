import { Attr, BelongsTo, Model } from "spraypaint";
import ApplicationRecord from "./ApplicationRecord";
import CheckoutPageProduct from "./CheckoutPageProduct";
import PaymentOption from "./PaymentOption";

@Model()
class CheckoutPagePaymentOption extends ApplicationRecord {
  public static jsonapiType = "checkout_page_payment_options";

  @BelongsTo() public checkoutPageProduct: CheckoutPageProduct;

  @Attr() public checkoutPageProductId: string;

  @BelongsTo() public paymentOption: PaymentOption;

  @Attr() public paymentOptionId: string;

  @Attr() public label: string;

  @Attr() public position: number;

  @Attr() public selectedByDefault: boolean;
}

export default CheckoutPagePaymentOption;
