import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import Vue from "vue";

// Initialize Sentry for error reporting
//
// Please note that if you enable this integration, Vue will not call its logError internally.
// This means that errors occurring in the Vue renderer will not show up in the developer console.
// If you want to preserve this functionality, make sure to pass the `logErrors: true` option.
Sentry.init({
  dsn: "https://05bf12f2f6e245db97417304d7ed8d6c@sentry.io/1541746",
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
  ]
});
