<template>
  <div v-if="required" class="shipping-address-area">
    <slot />
    <div class="shipping-address-fields">
      <input-shipping-address-address
        v-model="innerValue.address"
        :required="true"
      />
      <input-shipping-address-address2
        v-model="innerValue.address2"
        :required="false"
      />
      <input-shipping-address-zip v-model="innerValue.zip" :required="true" />
      <input-shipping-address-city v-model="innerValue.city" :required="true" />
      <input-shipping-address-state
        v-model="innerValue.state"
        :required="true"
      />
      <input-shipping-address-country
        v-model="innerValue.country"
        :required="true"
      />
    </div>
  </div>
</template>

<script>
import InputShippingAddressAddress from "components/InputShippingAddressAddress.vue";
import InputShippingAddressAddress2 from "components/InputShippingAddressAddress2.vue";
import InputShippingAddressCity from "components/InputShippingAddressCity.vue";
import InputShippingAddressCountry from "components/InputShippingAddressCountry.vue";
import InputShippingAddressState from "components/InputShippingAddressState.vue";
import InputShippingAddressZip from "components/InputShippingAddressZip.vue";

const props = {
  requires: {
    required: true,
    type: Object
  },
  value: {
    required: true,
    type: Object
  }
};

export default {
  components: {
    InputShippingAddressAddress,
    InputShippingAddressAddress2,
    InputShippingAddressCity,
    InputShippingAddressCountry,
    InputShippingAddressState,
    InputShippingAddressZip
  },
  props,
  data: () => ({
    innerValue: {
      address: "",
      address2: "",
      category: "shipping",
      city: "",
      country: "",
      state: "",
      zip: ""
    }
  }),
  computed: {
    required() {
      return this.requires.shippingAddress === true;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue: {
      // This is an object (nested values), which means a "deep" watcher is required:
      // https://stackoverflow.com/a/42134176/3411140
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  }
};
</script>
