import {
  Address,
  CheckoutPage,
  Customer,
  LineItem,
  Order,
  PaymentOption
} from "models";
import base64Generator from "utils/Base64Generator";

// actions
const actions = {
  selectDefaultPaymentMethod({ commit, getters, rootGetters }) {
    const checkoutPage = rootGetters["checkoutPages/current"];
    const paymentMethod = Object.keys(checkoutPage.paymentMethods)[0];
    commit("selectPaymentMethod", { getters, paymentMethod });
  },
  selectDefaultPaymentOptions({ commit, getters }) {
    const checkoutPage = CheckoutPage.find(getters.current.checkoutPageId);
    const { paymentOptionOrder } = checkoutPage;

    Object.values(paymentOptionOrder).forEach(product => {
      const {
        defaultPaymentOption,
        id: productId,
        required,
        paymentOptions
      } = product;
      // If there is no `defaultPaymentOption` and the product is `required`, simply pick the first.
      // TODO: Enforce on the server, so that every checkout page product always have a default payment option.
      const paymentOptionId =
        defaultPaymentOption ||
        (required ? paymentOptions[Object.keys(paymentOptions)[0]].id : null);

      commit("selectPaymentOption", { productId, paymentOptionId });
    });
  },
  selectPaymentMethod({ commit, getters }, paymentMethod) {
    commit("selectPaymentMethod", { getters, paymentMethod });
  },
  selectPaymentOption({ commit }, { productId, paymentOptionId }) {
    commit("selectPaymentOption", { productId, paymentOptionId });
  },
  setCurrentId({ commit }, id) {
    commit("setCurrentId", id);
  },
  setToken({ commit }, token) {
    commit("setToken", token);
  },
  updateField({ commit, getters }, { entity, field, value }) {
    commit("updateField", { getters, entity, field, value });
  }
};

// mutations
const mutations = {};

// getters
const getters = {};

// initial state
const state = {
  currentId: null,
  selectedPaymentMethod: null
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
