<template>
  <div class="card-fields">
    <component
      :is="`${componentPrefix}-${order[0]}`"
      :ref="`${componentPrefix}-${order[0]}`"
      :gateway="gateway"
      :order="order"
      :label-cols="labelCols"
      :label-cols-sm="labelColsSm"
      :label-cols-md="labelColsMd"
      :label-cols-lg="labelColsLg"
      :label-cols-xl="labelColsXl"
    />
    <component
      :is="`${componentPrefix}-${order[1]}`"
      :ref="`${componentPrefix}-${order[1]}`"
      :gateway="gateway"
      :order="order"
      :label-cols="labelCols"
      :label-cols-sm="labelColsSm"
      :label-cols-md="labelColsMd"
      :label-cols-lg="labelColsLg"
      :label-cols-xl="labelColsXl"
    />
    <component
      :is="`${componentPrefix}-${order[2]}`"
      :ref="`${componentPrefix}-${order[2]}`"
      :gateway="gateway"
      :order="order"
      :label-cols="labelCols"
      :label-cols-sm="labelColsSm"
      :label-cols-md="labelColsMd"
      :label-cols-lg="labelColsLg"
      :label-cols-xl="labelColsXl"
    />
  </div>
</template>

<script>
import CardFieldsStripeNumber from "components/CardFieldsStripeNumber.vue";
import CardFieldsStripeExpiry from "components/CardFieldsStripeExpiry.vue";
import CardFieldsStripeCvc from "components/CardFieldsStripeCvc.vue";

import EventBus from "mixins/eventBus";

export default {
  components: {
    CardFieldsStripeNumber,
    CardFieldsStripeExpiry,
    CardFieldsStripeCvc
  },
  props: {
    labelCols: { default: null, type: Number },
    labelColsSm: { default: null, type: Number },
    labelColsMd: { default: null, type: Number },
    labelColsLg: { default: null, type: Number },
    labelColsXl: { default: null, type: Number },
    gateway: {
      required: true,
      type: Object
    },
    order: {
      required: true,
      type: Array
    }
  },
  computed: {
    componentPrefix() {
      return "card-fields-stripe";
    }
  },
  mounted() {
    EventBus.$on("tokenize", () => {
      // A hacky way of grabbing an instance of a Stripe element:
      // https://github.com/fromAtoB/vue-stripe-elements/issues/67#issuecomment-497372327
      // eslint-disable-next-line no-underscore-dangle
      const cardNumberElement = this.$refs["card-fields-stripe-number"]
        .$children[0].$refs.CardNumber.$refs.element._element;
      EventBus.$emit("tokenizeWithStripe", cardNumberElement);
    });
  }
};
</script>

<style lang="scss">
.payment-field {
  // Match Bootstrap's line height for form fields.
  height: inherit;
}
</style>
