<template>
  <b-form-radio-group v-model="innerValue" stacked>
    <b-form-radio
      v-for="checkoutPagePaymentOption in paymentOptions"
      :key="checkoutPagePaymentOption.paymentOption.id"
      :value="checkoutPagePaymentOption.paymentOption.id"
      :disabled="editor"
      class="payment-option"
    >
      <span v-html="checkoutPagePaymentOption.label" />
    </b-form-radio>
  </b-form-radio-group>
</template>

<script>
export default {
  props: {
    paymentOptions: {
      required: true,
      type: Array
    },
    productId: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: String
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    editor() {
      return this.$store.getters.editor;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    // Handles external model changes.
    value(newValue) {
      this.innerValue = newValue;
    }
  }
};
</script>
